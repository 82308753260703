import { useState } from 'react';

export const useResetPassword = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    
    const resetPassword = async (userId, key, newPassword, newPasswordConfirm) => {
        setIsLoading(true);
        
        const response = await fetch('/api/email/reset/key', {
            method: 'POST',
            body: JSON.stringify({ userId, key, newPassword, newPasswordConfirm }),
            headers: { 'Content-Type': 'application/json' }
        });

        const json = await response.json();

        if (!response.ok) {
            setIsLoading(false);
            setError(json.error);

            return false;
        }

        if (response.ok) {
            setIsLoading(false);
            setError(null);

            return true;
        }
    }

    return { resetPassword, isLoading, error }
}