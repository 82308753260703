import { Link } from "react-router-dom"

const About = () => {
    return (
        <div className='text-center'>
            <p className='mt-8 text-6xl font-bold'>About Trip Fern</p>
            <p className='mt-8 text-xl'>Trip Fern was created to help you document your travels better.</p>
            <p className='mt-8 text-xl'>That's it...</p>
            <Link to='/'>
                <button type='button' className='mt-8 py-2 px-4 text-white bg-blue-500 font-bold text-lg rounded-md '>Create an Account / Login</button>
            </Link>
        </div>
    )
}

export default About