import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useAuthContext } from '../hooks/useAuthContext';

const VerifyEmail = () => {
    // Context / Hooks
    const { user } = useAuthContext();

    // URL Params
    const { userId, key } = useParams();
    
    // Local State
    const [status, setStatus] = useState('');

    useEffect(() => {
        const fetchVerifyEmail = async () => {
            const response = await fetch('/api/email/verify/key', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ userId, key })
            });
            const json = await response.json();

            if (!response.ok) {
                setStatus('error');
            }

            if (response.ok && json.success === true) {
                setStatus('success');
                const verifyEmailPopup = document.getElementById('verify-email-popup');
                if (verifyEmailPopup) {
                    verifyEmailPopup.classList.add('hidden');
                }

                if (user) {
                    let userItem = JSON.parse(localStorage.getItem('tsb_user'));
                    userItem.verified = true;
                    localStorage.setItem('tsb_user', JSON.stringify(userItem));
                }
            }
        }

        fetchVerifyEmail();
    }, [user, userId, key]);

    return (
        <div>
            {status === 'error' && (
                <div className='border border-red-400 bg-red-50 p-4 rounded-sm'>
                    <p className='text-center text-lg'>That verification link either expired, or doesn't exist.</p>
                    {!user ? (
                        <>
                            <p className='mt-4 text-center text-lg'>To resend your verification email, you must first login.</p>
                            <div className='mt-4 text-center'>
                                <Link to='/login'>
                                    <button type='button' className='py-2 px-4 bg-blue-500 text-white rounded-sm hover:bg-blue-600 transition'>Login</button>
                                </Link>
                            </div>
                        </>
                    ) : (
                        <p className='mt-4 text-center text-lg'>Tap the "Resend Email" link in the yellow box above to resend your verification email.</p>
                    )}
                </div>
            )}
            {status === 'success' && (
                <div className='border border-green-400 bg-green-50 p-4 rounded-sm'>
                    <p className='text-center text-lg'>Success! Your email has been verified.</p>
                    {user ? (
                        <div className='text-center mt-4'>
                            <Link to='/'>
                                <button type='button' className='py-2 px-4 bg-blue-500 text-white rounded-sm hover:bg-blue-600 transition'>Return Home</button>
                            </Link>
                        </div>
                    ) : (
                        <div className='text-center mt-4'>
                            <Link to='/login'>
                                <button type='button' className='py-2 px-4 bg-blue-500 text-white rounded-sm hover:bg-blue-600 transition'>Login</button>
                            </Link>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default VerifyEmail