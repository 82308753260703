import LocationsSearchBar from "../components/locations/LocationSearchBar"

const LocationSearch = () => {
    return (
        <div>
            <h3 className='text-2xl font-bold mb-4'>Recommendations</h3>
            <p>Search for Recommendations by City/Town</p>
            <LocationsSearchBar />
        </div>
    )
}

export default LocationSearch