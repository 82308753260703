import { useEffect, useState } from "react";
import { useAuthContext } from "../hooks/useAuthContext"
import { useChangeName } from "../hooks/user/useChangeName";
import { useUpdateAccountType } from "../hooks/stripe/useUpdateAccountType";

import PricingTable from "../components/pricing/PricingTable";

import dayjs from 'dayjs';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import LinearProgress from '@mui/material/LinearProgress';
import Skeleton from '@mui/material/Skeleton';

const Account = () => {
    const { user } = useAuthContext();
    const { changeName } = useChangeName();

    const { updateAccountType, isLoading, error } = useUpdateAccountType();

    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userAccountType, setUserAccountType] = useState('');

    const [subscriptionData, setSubscriptionData] = useState(null);

    const [storageUsed, setStorageUsed] = useState(0);
    const [storageAvailable, setStorageAvailable] = useState(0);
    const [totalStorage, setTotalStorage] = useState(0);

    const [newName, setNewName] = useState('');

    const [showPricingTable, setShowPricingTable] = useState(false);
    const [showUpgradeAccount, setShowUpgradeAccount] = useState(false);
    const [showChangeDisplayName, setShowChangeDisplayName] = useState(false);

    const [accountType, setAccountType] = useState('');
    const [accountTypeSelected, setAccountTypeSelected] = useState(false);

    const [newAccountType, setNewAccountType] = useState('');

    const [accountDataIsLoading, setAccountDataIsLoading] = useState(false);

    const [invoiceDialogOpen, setInvoiceDialogOpen] = useState(false);
    const handleInvoiceDialogOpen = () => { setInvoiceDialogOpen(true) }
    const handleInvoiceDialogClose = () => { setInvoiceDialogOpen(false) }

    const handleNameChange = async (event) => {
        event.preventDefault();

        const updatedUser = await changeName(newName);
        
        if (updatedUser) {
            setUserName(updatedUser.name);
            setNewName('');
        }
    }

    const toggleShowChangeDisplayName = () => {
        setShowChangeDisplayName(!showChangeDisplayName);
    }

    const handleUpgradeAccount = async (event) => {
        event.preventDefault();

        if (
            (userAccountType === 'free' && (newAccountType === 'travel' || newAccountType === 'adventure')) ||
            (userAccountType === 'travel' && newAccountType === 'adventure')
        ) {
            const updateSuccess = await updateAccountType(newAccountType);

            if (updateSuccess) {
                setUserAccountType(newAccountType);
            }
        }
    }

    const handleUpgradeFreeAccount = () => {

        if (userAccountType === 'free' && (accountType === 'travel' || accountType === 'adventure')) {
            document.getElementById('upgrade-account-form').submit();
        }
    }

    useEffect(() => {
        const fetchUserAccountData = async () => {
            setAccountDataIsLoading(true);

            const response = await fetch('/api/user', {
                headers: { 'Authorization': `Bearer ${user.token}`}
            });
            const json = await response.json();

            if (!response.ok) {
                setAccountDataIsLoading(false);
            }

            if (response.ok) {
                setAccountDataIsLoading(false);
                setUserName(json.userData.name);
                setUserEmail(json.userData.email);
                setUserAccountType(json.userData.accountType);
                setStorageUsed(json.userUsedStorage);

                let storageAvailable;
                if (json.userData.accountType === 'free') {
                    storageAvailable = 500 * 1000000;
                } else if (json.userData.accountType === 'travel') {
                    storageAvailable = 5000 * 1000000;
                } else if (json.userData.accountType === 'adventure') {
                    storageAvailable = 50000 * 1000000;
                }
                setTotalStorage(storageAvailable);

                setStorageAvailable(storageAvailable - json.userUsedStorage)

                if (json.userSubscriptionData) {
                    setSubscriptionData(json.userSubscriptionData);
                }

                let newSelectedAccountType;

                if (json.userData.accountType === 'free') {
                    newSelectedAccountType = 'travel';
                } else if (json.userData.accountType === 'travel') {
                    newSelectedAccountType = 'adventure';
                }

                setAccountType(newSelectedAccountType);
            }
        }

        if (user) {
            fetchUserAccountData();
        }
    }, [user]);

    return (
        <div>
            <h2 className='text-4xl font-bold'>My Account</h2>
            <div className='mt-4 border-b border-gray-300 pb-4'>
                <div className='border-b border-gray-300 pb-4'>
                    {accountDataIsLoading ? (
                        <div className='w-full md:w-1/2'>
                            <Skeleton variant='rounded' height={60} />
                        </div>
                    ) : (
                        <>
                            <p className='text-2xl'>Name: {userName}</p>
                            <button className='underline hover:no-underline' onClick={toggleShowChangeDisplayName}>Change Display Name</button>
                        </>
                    )}
                    {showChangeDisplayName && (
                        <div className='mt-4'>
                            <form onSubmit={handleNameChange}>
                                <div>
                                    <p>Update Display Name:</p>
                                    <input 
                                        type='text' 
                                        className='w-full sm:w-[400px] p-2 text-lg border border-gray-300 rounded-sm' 
                                        value={newName}
                                        onChange={(e) => setNewName(e.target.value)}
                                    />
                                </div>
                                <button
                                    type='submit'
                                    className='w-full sm:w-[400px] mt-2 py-2 px-4 text-white bg-blue-500 hover:bg-blue-600 transition text-lg rounded-sm'
                                >Change Name</button>
                            </form>
                        </div>
                    )}
                </div>
                <div className='border-b border-gray-300 mt-4 pb-4'>
                    {accountDataIsLoading ? (
                        <div className='w-full md:w-1/2'>
                            <Skeleton variant='rounded' height={32} />
                        </div>
                    ) : (
                        <p className='text-2xl'>Email: {userEmail}</p>
                    )}
                </div>
                <div className='mt-4 pb-4'>
                    {accountDataIsLoading ? (
                        <div className='w-full md:w-1/2'>
                            <Skeleton variant='rounded' height={240} />
                        </div>
                    ) : (
                        <>
                            <p className='text-2xl'>Account Type: {
                                userAccountType === 'free' 
                                    ? 'Free' 
                                    : userAccountType === 'travel' 
                                        ? 'Travel' 
                                        : userAccountType === 'adventure' 
                                            ? 'Adventure' 
                                            : 'N/A'
                            }</p>
                            <div className='mt-2'>
                                {userAccountType === 'free' && <p>Storage Total: 500MB</p>}
                                {userAccountType === 'travel' && <p>Storage Total: 5GB (5,000MB)</p>}
                                {userAccountType === 'adventure' && <p>Storage Total: 50GB (50,000MB)</p>}
                                <p>Storage Used: {(storageUsed / 1000000).toFixed(2)}MB</p>
                                <p>Storage Available: {(storageAvailable / 1000000).toFixed(2)}MB ({((storageUsed / totalStorage) * 100).toFixed(2)}% Used)</p>
                                <div className='mt-2 w-full md:w-[400px]'>
                                    <LinearProgress 
                                        variant='determinate' 
                                        value={Math.ceil((storageUsed / totalStorage) * 100)} 
                                        color={Math.ceil((storageUsed / totalStorage) * 100) >= 75 ? 'warning' : 'success'}
                                    />
                                </div>
                            </div>
                            {subscriptionData && (
                                <div className='mt-4'>
                                    <div>
                                        <p>Current Plan Price: ${(subscriptionData.price / 100).toFixed(2)} / month</p>
                                        <p>Current Plan Period: {dayjs(subscriptionData.periodStart).format('MMM DD, YYYY')} - {dayjs(subscriptionData.periodEnd).format('MMM DD, YYYY')}</p>
                                    </div>
                                    {subscriptionData.nextInvoiceData && (
                                        <div className='mt-2'>
                                            <p>Next Invoice: ${(subscriptionData.nextInvoiceData.amountDue / 100).toFixed(2)} on {dayjs(subscriptionData.nextInvoiceData.dueDate).format('MMM DD, YYYY')}</p>
                                            <button
                                                type='button'
                                                onClick={handleInvoiceDialogOpen}
                                                className='underline hover:no-underline text-sm'
                                            >View Invoice Details</button>
                                        </div>
                                    )}
                                </div>
                            )}
                            {userAccountType === 'free' ? (
                                <div className='mt-4'>
                                    <button
                                        onClick={() => setShowPricingTable(!showPricingTable)}
                                        className='py-2 px-4 bg-blue-500 hover:bg-blue-600 transition text-white font-bold rounded-sm'
                                    >Upgrade Account</button>
                                </div>
                            ) : (
                                <div className='mt-4'>
                                    <button 
                                        onClick={() => setShowUpgradeAccount(!showUpgradeAccount)}
                                        className='py-2 px-4 bg-blue-500 hover:bg-blue-600 transition text-white rounded-sm font-bold'
                                    >Upgrade Account</button>
                                </div>
                            )}
                        </>
                    )}
                    {showUpgradeAccount && (
                        <div className='mt-4'>
                            {userAccountType === 'adventure' ? (
                                <>
                                    <p>You're already on the highest plan!</p>
                                    <p className='mt-2'>Need more space? Contact support@tripfern.com</p>
                                </>
                            ) : (
                                <>
                                    <p className='font-bold'>Choose New Account Type</p>
                                    <form onSubmit={handleUpgradeAccount}>
                                        <div className='mt-2'>
                                            <select 
                                                className='p-2 text-lg border border-gray-300 rounded-sm' 
                                                value={newAccountType} 
                                                onChange={(e) => setNewAccountType(e.target.value)}
                                            >
                                                <option value=''>Select a New Account Type</option>
                                                {userAccountType === 'free' && (
                                                    <>
                                                        <option value='travel'>Travel (5GB) - $0.99/month</option>
                                                        <option value='adventure'>Adventure (50GB) - $4.99/month</option>
                                                    </>
                                                )}
                                                {userAccountType === 'travel' && (
                                                    <>
                                                        <option value='adventure'>Adventure (50GB) - $4.99/month</option>
                                                    </>
                                                )}
                                            </select>
                                        </div>
                                        <div className='mt-2'>
                                            <button
                                                type='submit'
                                                disabled={isLoading}
                                                className='py-2 px-4 bg-blue-500 hover:bg-blue-600 transition text-white font-bold rounded-sm'
                                            >Upgrade</button>
                                        </div>
                                        {isLoading && (
                                            <div className='mt-2'>
                                                <LinearProgress variant='indeterminate' />
                                            </div>
                                        )}
                                    </form>
                                </>
                            )}
                        </div>
                    )}
                </div>
            </div>
            {showPricingTable && (
                <>
                    <div className='text-center mt-8'>
                        <button
                            onClick={handleUpgradeFreeAccount}
                            className='py-2 px-4 text-2xl font-bold bg-blue-500 hover:bg-blue-600 transition text-white rounded-sm'
                        >Upgrade</button>
                        <form action='/api/stripe/upgrade' id='upgrade-account-form' method='POST'>
                            <input type='hidden' name='accountEmail' value={userEmail} />
                            <input type='hidden' name='accountType' value={accountType} />
                        </form>
                    </div>
                    <PricingTable accountType={accountType} setAccountType={setAccountType} setAccountTypeSelected={setAccountTypeSelected}/>
                </>
            )}
            <Dialog
                open={invoiceDialogOpen}
                onClose={handleInvoiceDialogClose}
                aria-labelledby='invoice-dialog-title'
                aria-describedby='invoice-dialog-description'
            >
                <DialogTitle id='invoice-dialog-title'>
                    {'Invoice Details'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id='invoice-dialog-description'>
                        {'Listed below are the line items for your next upcoming invoice.'}
                    </DialogContentText>
                    <div className='mt-4'>
                        <DialogContentText>
                            {'Note: If you just upgraded your account, you will see charges for the remainder of the current month plus the next month.'}
                        </DialogContentText>
                    </div>
                    {(subscriptionData && subscriptionData.nextInvoiceData) ? (
                        <div>
                            {subscriptionData.nextInvoiceData.lines.map((l) => (
                                <div className='mt-4'>
                                    <p>{l.amount <= 0 ? 'Credit' : 'Cost'}: ${(l.amount / 100).toFixed(2)}</p>
                                    <p>Description: {l.description}</p>
                                </div>
                            ))}
                            <div className='mt-4'>
                                <p className='font-bold'>Total Due: ${(subscriptionData.nextInvoiceData.amountDue / 100).toFixed(2)} on {dayjs(subscriptionData.nextInvoiceData.dueDate).format('MMM DD, YYYY')}</p>
                                <p className='mt-4 text-sm'>Have questions? Email support@tripfern.com and we'll be happy to help!</p>
                            </div>
                        </div>
                    ) : (
                        <DialogContentText>
                            {'Sorry, we cannot seem to find invoice data. If you believe this is an error, contact help@tripfern.com'}
                        </DialogContentText>
                    )}
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default Account