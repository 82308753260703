import { useState } from 'react';
import { useAuthContext } from '../useAuthContext';

export const useCreateActivity = () => {
    const { user } = useAuthContext();

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const createActivity = async (tripId, activityName) => {
        setIsLoading(true);

        const response = await fetch('/api/trip/activity/create', {
            method: 'POST',
            body: JSON.stringify({ tripId, activityName }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        });

        const json = await response.json();

        if (!response.ok) {
            setIsLoading(false);
            setError(json.error);
        
            console.log('Nope');
            return false;
        }

        if (response.ok) {
            setIsLoading(false);
            setError(null);

            console.log('Yeup');
            return json;
        }
    }

    return { createActivity, isLoading, error }
}