import { createContext, useReducer } from 'react';

export const InvitationContext = createContext();

export const invitationReducer = (state, action) => {
    switch (action.type) {
        case 'SET_INVITATIONS':
            return {
                invitations: action.payload
            }
        case 'DELETE_INVITATION':
            return {
                invitations: state.invitations.filter((i) => i._id !== action.payload._id)
            }
        case 'UPDATE_INVITATION':
            return {
                invitations: state.invitations.map((i) => i._id === action.payload._id ? action.payload : i)
            }
        default:
            return state
    }
}

export const InvitationContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(invitationReducer, {
        invitations: []
    });

    return (
        <InvitationContext.Provider value={{...state, dispatch}}>
            {children}
        </InvitationContext.Provider>
    )
}