import { InvitationContext } from '../context/InvitationContext';
import { useContext } from 'react';

export const useInvitationContext = () => {
    const context = useContext(InvitationContext);

    if (!context) {
        throw Error('useInvitationContext must be used inside an InvitationContextProvider')
    }
    return context;
}