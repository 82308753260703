// React Utils
import { useEffect, useState } from 'react';

// React Router Utils
import { useNavigate } from 'react-router-dom';

// Hooks / Context
import { useAuthContext } from '../../hooks/useAuthContext';
import { useSendInvitation } from '../../hooks/invitation/useSendInvitation';

// Components
import CreateTrip from './CreateTrip';

// MUI Components
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import CardActionArea from '@mui/material/CardActionArea';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';

const MyTrips = () => {
    const { user } = useAuthContext();
    const navigate = useNavigate();

    const [myTrips, setMyTrips] = useState([]);
    const [myTripsIsLoading, setMyTripsIsLoading] = useState(false);
    const [myTripsError, setMyTripsError] = useState(null);

    const [tripToShare, setTripToShare] = useState(null);
    const [shareDialogOpen, setShareDialogOpen] = useState(false);
    
    const [invitationEmail, setInvitationEmail] = useState('');
    const [tripInvitations, setTripInvitations] = useState([]);

    const { sendInvitation, isLoading, error } = useSendInvitation();

    const handleShareDialogOpen = (trip) => {
        setTripToShare(trip);
        fetchTripInvitations(trip._id);
        setShareDialogOpen(true);
    }
    
    const handleShareDialogClose = () => {
        setShareDialogOpen(false);
    }

    const handleShareEmailSubmit = async (event) => {
        event.preventDefault();

        if (tripToShare && invitationEmail) {
            const newInvitation = await sendInvitation(tripToShare._id, invitationEmail);
        
            if (newInvitation) {
                setInvitationEmail('');
                setTripInvitations([...tripInvitations, newInvitation])
            }
        }
    }

    const [showCreateTrip, setShowCreateTrip] = useState(false);
    const toggleShowCreateTrip = () => { setShowCreateTrip(!showCreateTrip) }

    const fetchTripInvitations = async (tripId) => {
        const response = await fetch(`/api/invitation/trip/${tripId}`, {
            headers: { 'Authorization': `Bearer ${user.token}` }
        });
        const json = await response.json();

        if (!response.ok) {
            console.log('Error Fetching Trip Invitations');
        }

        if (response.ok) {
            setTripInvitations(json);
        }
    }

    useEffect(() => {
        const fetchTrips = async () => {
            setMyTripsIsLoading(true);
            const response = await fetch('/api/trip/all', {
                headers: { 'Authorization': `Bearer ${user.token}` }
            });

            const json = await response.json();

            if (response.ok) {
                setMyTripsIsLoading(false);
                setMyTripsError(null);
                setMyTrips(json);
            }

            if (!response.ok) {
                setMyTripsIsLoading(false);
                setMyTripsError('Error loading your trips');
            }
        }

        if (user) {
            fetchTrips();
        }
    }, [user]);

    return (
        <div>
            <h2 className='text-4xl mt-8'>My Trips</h2>
            {myTripsIsLoading ? (
                <div className='mt-4'>
                    <LinearProgress variant='indeterminate' />
                </div>
            ) : (
                <>
                    {myTripsError && <p className='text-red-400'>{myTripsError}</p>}
                    {myTrips.length > 0 ? (
                        <div className='mt-4 grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
                            {myTrips.map((trip) => (
                                <Card key={trip._id}>
                                    <CardActionArea onClick={() => navigate(`/trip/${trip._id}`)}>
                                        <CardHeader title={trip.name} subheader={trip.identifier} />
                                        {trip.coverUrl ? (
                                            <CardMedia component="img" image={trip.coverUrl} alt='' sx={{ height: '300px' }} />
                                        ) : (
                                            <CardMedia component="img" sx={{ height: '300px' }} image="/assets/images/no-photos-placeholder.jpg" alt="Image Showing No Photos have been uploaded yet." />
                                        )}
                                    </CardActionArea>
                                    <CardActions>
                                        <Button size='small' color='primary' onClick={() => handleShareDialogOpen(trip)}>Share</Button>
                                    </CardActions>
                                </Card>
                            ))}
                            <Card>
                                <CardContent className='h-full'>
                                    <div className='flex justify-center items-center h-full'>
                                        <button 
                                            type='button'
                                            onClick={toggleShowCreateTrip}
                                            className='py-2 px-4 bg-blue-500 hover:bg-blue-600 transition text-white text-lg font-bold rounded-sm'
                                            >Add a New Trip</button>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    ) : (
                        <div>
                            <p className='mt-4 text-xl'>You have no saved trips.</p>
                            <div className='mt-4'>
                                <button 
                                    type='button' 
                                    onClick={toggleShowCreateTrip}
                                    className='py-2 px-4 bg-blue-500 rounded-sm text-white text-xl font-bold hover:bg-blue-600 transition'
                                >Add a Trip</button>
                            </div>
                        </div>
                    )}
                </>
            )}
            {showCreateTrip && <CreateTrip />}
            <Dialog
                open={shareDialogOpen}
                onClose={handleShareDialogClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>
                    {tripToShare && `Share ${tripToShare.identifier}`}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        To share a trip:<br />1. Enter the email(s) you wish to share with below.
                    </DialogContentText>
                    <div className='mt-4'>
                        <form onSubmit={handleShareEmailSubmit} className='mt-4'>
                            <p>Enter an Email:</p>
                            <div className='flex'>
                                <input 
                                    type='text' 
                                    placeholder='billybob@thorton.com' 
                                    className='border border-gray-300 rounded-sm mr-2 w-full px-2' 
                                    value={invitationEmail}
                                    onChange={(e) => setInvitationEmail(e.target.value)}
                                />
                                <button 
                                    type='submit' 
                                    disabled={isLoading}
                                    className='py-2 px-4 bg-blue-500 hover:bg-blue-600 transition text-white rounded-sm'
                                >Invite</button>
                            </div>
                        </form>
                        {error && <p className='text-red-400'>{error}</p>}
                    </div>
                    <div className='mt-4'>
                        <p className='font-bold'>Invitations Sent:</p>
                        {tripInvitations.length > 0 && (
                            tripInvitations.map((ti) => <p key={ti._id}>{ti.toEmail}</p>)
                        )}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleShareDialogClose} color='primary'>Done</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default MyTrips