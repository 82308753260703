import { useState } from 'react';
import { useAuthContext } from '../useAuthContext';

export const useResendVerificationEmail = () => {
    const { user } = useAuthContext();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const resendVerificationEmail = async () => {
        setIsLoading(true);

        const response = await fetch('/api/email/verify/resend', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            body: JSON.stringify({resend: true})
        });
        
        const json = await response.json();

        if (!response.ok) {
            setIsLoading(false);
            setError(json.error);
            return false;
        }

        if (response.ok) {
            setIsLoading(false);
            setError(null);
            return true;
        }
    }

    return { resendVerificationEmail, isLoading, error }
}