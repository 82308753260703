import { useState } from 'react';
import { useAuthContext } from "../useAuthContext"

export const useAddVote = () => {
    const { user } = useAuthContext();

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const addVote = async (recommendationId) => {
        setIsLoading(true);
        
        const response = await fetch(`/api/recommendation/vote/for/${recommendationId}`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${user.token}` }
        });

        const json = await response.json();

        if (!response.ok) {
            setIsLoading(false);
            setError(json.error);

            return false;
        }

        if (response.ok) {
            setIsLoading(false);
            setError(null);

            return json;
        }
    }

    return { addVote, isLoading, error }
}