import { useEffect, useState } from 'react';

import { Link, useNavigate } from 'react-router-dom';

import { useLogout } from '../../hooks/auth/useLogout';
import { useAuthContext } from "../../hooks/useAuthContext";
import { useInvitationContext } from '../../hooks/useInvitationContext';

// MUI Components
import Badge from '@mui/material/Badge';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

// MUI Icons
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import MailIcon from '@mui/icons-material/Mail';
import SettingsIcon from '@mui/icons-material/Settings';
import RecommendIcon from '@mui/icons-material/Recommend';
import ChatIcon from '@mui/icons-material/Chat';
// import NotificationsIcon from '@mui/icons-material/Notifications';

const Nav = () => {
    const { user } = useAuthContext();
    const { invitations, dispatch } = useInvitationContext();

    const { logout } = useLogout();

    const navigate = useNavigate();

    // Menus
    const [settingsMenuAnchorEl, setSettingsMenuAnchorEl] = useState(null);
    const [invitationsMenuAnchorEl, setInvitationsMenuAnchorEl] = useState(null);
    const [recommendationsMenuAnchorEl, setRecommendationsMenuAnchorEl] = useState(null);
    const settingsMenuOpen = Boolean(settingsMenuAnchorEl);
    const invitationsMenuOpen = Boolean(invitationsMenuAnchorEl);
    const recommendationsMenuOpen = Boolean(recommendationsMenuAnchorEl);
    const handleSettingsMenuClick = (event) => { setSettingsMenuAnchorEl(event.currentTarget) }
    const handleInvitationsMenuClick = (event) => { setInvitationsMenuAnchorEl(event.currentTarget) }
    const handleRecommendationsMenuClick = (event) => { setRecommendationsMenuAnchorEl(event.currentTarget) }
    const handleSettingsMenuClose = () => { setSettingsMenuAnchorEl(null) }
    const handleInvitationsMenuClose = () => { setInvitationsMenuAnchorEl(null) }
    const handleRecommendationsMenuClose = () => { setRecommendationsMenuAnchorEl(null) }

    const handleLogoutClick = () => {
        handleSettingsMenuClose();
        logout();
    }

    const handleNavigateTo = (to) => {
        handleSettingsMenuClose();
        handleInvitationsMenuClose();
        handleRecommendationsMenuClose();
        
        if (to === 'account') {
            navigate('/account');
        } else if (to === 'invitations') {
            navigate('/invitations');
        } else if (to === 'recommendations-search') {
            navigate('/location/search');
        } else if (to === 'recommendations-bookmarks') {
            navigate('/recommendations/bookmarks');
        } else if (to === 'feedback') {
            navigate('/feedback');
        }
    }

    useEffect(() => {
        const fetchInvitations = async () => {
            const response = await fetch('/api/invitation/user', {
                headers: { 'Authorization': `Bearer ${user.token}` }
            });
            const json = await response.json();

            if (!response.ok) {
                //console.log('Error Fetching Invitations:', json.error);
            }

            if (response.ok) {
                dispatch({ type: 'SET_INVITATIONS', payload: json });
            }
        }

        if (user) {
            fetchInvitations();
        }
    }, [dispatch, user]);

    return (
        <header>
            <div className='max-w-7xl mx-auto flex justify-between items-center p-4'>
                <h1 className='text-xl font-bold'>Trip Fern</h1>
                <nav>
                    {user ? (
                        <>
                            <Link to='/'>
                                <HomeIcon color='action' />
                            </Link>&nbsp;&nbsp;
                            <button onClick={handleInvitationsMenuClick}>
                                <Badge badgeContent={invitations.filter((i) => i.seen === false).length} color='primary'>
                                    <MailIcon color='action' />
                                </Badge>
                            </button>&nbsp;&nbsp;
                            <button onClick={handleRecommendationsMenuClick}>
                                <RecommendIcon color='action' />
                            </button>&nbsp;&nbsp;
                            <button onClick={() => handleNavigateTo('feedback')}>
                                <ChatIcon color='action' />
                            </button>&nbsp;&nbsp;
                            <button onClick={handleSettingsMenuClick}>
                                <SettingsIcon color='action' />
                            </button>
                        </>
                    ) : (
                        <>
                            <Link to='/'><HomeIcon color='action' /></Link>&nbsp;&nbsp;
                            <Link to='/about'><InfoIcon color='action' /></Link>
                        </>
                    )}
                </nav>
            </div>
            <Menu
                anchorEl={settingsMenuAnchorEl}
                open={settingsMenuOpen}
                onClose={handleSettingsMenuClose}
            >
                <MenuItem onClick={() => handleNavigateTo('account')}>Account</MenuItem>
                <MenuItem onClick={handleLogoutClick}>Logout</MenuItem>
            </Menu>
            <Menu
                anchorEl={invitationsMenuAnchorEl}
                open={invitationsMenuOpen}
                onClose={handleInvitationsMenuClose}
            >
                <MenuItem onClick={() => handleNavigateTo('invitations')}>View New Invitations</MenuItem>
            </Menu>
            <Menu
                anchorEl={recommendationsMenuAnchorEl}
                open={recommendationsMenuOpen}
                onClose={handleRecommendationsMenuClose}
            >
                <MenuItem onClick={() => handleNavigateTo('recommendations-search')}>Find Recommendations</MenuItem>
                <MenuItem onClick={() => handleNavigateTo('recommendations-bookmarks')}>My Bookmarks</MenuItem>
            </Menu>
        </header>
    )
}

export default Nav