import { useState } from 'react';
import { useAuthContext } from '../useAuthContext';

export const useUpdateStartDate = () => {
    const { user } = useAuthContext();

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const updateStartDate = async (newDate, tripId) => {
        setIsLoading(true);

        const response = await fetch(`/api/trip/update/date/${tripId}`, {
            method: 'PATCH',
            body: JSON.stringify({newDate}),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        });

        const json = await response.json();

        if (!response.ok) {
            setIsLoading(false);
            setError(json.error);

            return false;
        }

        if (response.ok) {
            setIsLoading(false);
            setError(null);

            return json;
        }
    }

    return { updateStartDate, isLoading, error }
}