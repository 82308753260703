import { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';

import { useResetPassword } from '../hooks/email/useResetPassword';

import LinearProgress from '@mui/material/LinearProgress';

const ResetPassword = () => {

    const navigate = useNavigate();

    const { userId, key } = useParams();
    
    const { resetPassword, isLoading, error } = useResetPassword();

    const [validLink, setValidLink] = useState(false);
    const [linkCheckIsLoading, setLinkCheckIsLoading] = useState(false);

    const [newPassword, setNewPassword] = useState('');
    const [newPasswordConfirm, setNewPasswordConfirm] = useState('');

    const [passwordWasReset, setPasswordWasReset] = useState(false);

    const handleSubmitNewPassword = async (event) => {
        event.preventDefault();

        const newPasswordSet = await resetPassword(userId, key, newPassword, newPasswordConfirm);

        if (newPasswordSet) {
            setPasswordWasReset(true);
        }
    }

    useEffect(() => {
        const fetchResetValidation = async () => {
            setLinkCheckIsLoading(true);

            const response = await fetch('/api/email/reset/check', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userId, key })
            });

            const json = await response.json();

            if (!response.ok) {
                setLinkCheckIsLoading(false);
                setValidLink(false);
                navigate('/login');
                return false;
            }

            if (response.ok) {
                setLinkCheckIsLoading(false);
                setValidLink(true);
                return true;
            }
        }

        fetchResetValidation();
    }, [userId, key]);

    return (
        <div>
            {linkCheckIsLoading && (
                <LinearProgress variant='indeterminate' />
            )}

            {validLink && (
                passwordWasReset ? (
                    <div className='w-full md:w-[400px] mx-auto mt-4 md:mt-12 p-8 bg-white rounded-md'>
                        <h3 className='font-bold text-lg'>Success! Password was reset!</h3>
                        <p className='mt-4'>You can now <Link to='/login' className='underline hover:no-underline'>login to your account</Link>.</p>
                    </div>
                ) : (
                    /* Reset Password Form */
                    <form onSubmit={handleSubmitNewPassword} className='w-full md:w-[400px] mx-auto mt-4 md:mt-12 p-8 bg-white rounded-md'>
                        {isLoading && <LinearProgress variant='indeterminate' />}
                        <div>
                            <h3 className='text-lg font-bold'>Reset Your Password</h3>
                        </div>
                        {error && (
                            <div className='mt-4'>
                                <p className='text-red-400'>{error}</p>
                            </div>
                        )}
                        <div className='mt-4'>
                            <p>New Password:</p>
                            <input 
                                type='password'
                                className='w-full p-2 text-lg border border-gray-300'
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                        </div>
                        <div className='mt-4'>
                            <p>Confirm New Password:</p>
                            <input
                                type='password'
                                className='w-full p-2 text-lg border border-gray-300'
                                value={newPasswordConfirm}
                                onChange={(e) => setNewPasswordConfirm(e.target.value)}
                            />
                        </div>
                        <div className='mt-4'>
                            <button
                                type='submit'
                                className='w-full py-2 px-4 font-bold text-white bg-blue-500 hover:bg-blue-600 transition'
                            >Update Password</button>
                        </div>
                    </form>
                )
            )}
        </div>
    )
}

export default ResetPassword