// React Utilities
import { useState } from 'react';

// Hooks
import { useAuthContext } from '../../hooks/useAuthContext';
//import { useAddImageToSale } from '../../hooks/sales/useAddImageToSale';

// Dropzone
import { useDropzone } from 'react-dropzone';

// MUI Icons
import SaveAltIcon from '@mui/icons-material/SaveAlt';

// MUI Components
import LinearProgress from '@mui/material/LinearProgress';

const UploadMedia = ({ tripId, setShowUpload }) => {
    const { user } = useAuthContext();

    const [files, setFiles] = useState([]);
    //const [showAddImages, setShowAddImages] = useState(false);
    const [uploadErrors, setUploadErrors] = useState([]);

    // Progress States
    const [progressValues, setProgressValues] = useState([]);

    const {getRootProps, getInputProps} = useDropzone({
        accept: {
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/png': ['.png'],
            'video/mp4': ['.mp4'],
            'video/mov': ['.mov']
        },
        onDrop: (acceptedFiles) => handleAcceptedFiles(acceptedFiles)
    });

    const hideAddImages = () => {
        setShowUpload(false);
        setFiles([]);
    }

    const handleAcceptedFiles = async (acceptedFiles) => {
        setUploadErrors([]);
        const newAcceptedFiles = acceptedFiles.map((f) => Object.assign(f, {
            preview: URL.createObjectURL(f)
        }));
        const newProgressValues = newAcceptedFiles.map((f) => {
            return {
                'color': 'primary',
                'variant': 'indeterminate'
            }
        })
        setProgressValues(newProgressValues);
        setFiles(newAcceptedFiles);

        //let newImages = [];
        for (let i = 0; i < acceptedFiles.length; i++) {
            let formData = new FormData();
            formData.append('image', acceptedFiles[i]);
            const response = await fetch(`/api/media/trip/${tripId}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${user.token}`,
                },
                body: formData
            });
            const json = await response.json();

            if (response.ok) {
                let updatedProgressValues = newProgressValues.map((p) => p);
                updatedProgressValues[i].color = 'success';
                updatedProgressValues[i].variant = 'determinate';
                setProgressValues(updatedProgressValues);
                // setMySales(mySales.map((s) => s._id === json._id ? json : s));
            }

            if (!response.ok) {
                setUploadErrors([...uploadErrors, json.error]);
                let updatedProgressValues = newProgressValues.map((p) => p);
                updatedProgressValues[i].color = 'error';
                updatedProgressValues[i].variant = 'determinate';
                setProgressValues(updatedProgressValues);
            }
        }

    }

    const thumbs = files.map((f, index) => (
        <div className='' key={'preview-image-'+index}>
            <div className=''>
                <img
                    src={f.preview}
                    className='max-h-48 border rounded-sm p-2 border-gray-300'
                    onLoad={() => { URL.revokeObjectURL(f.preview)}}
                    alt=''
                />
                <LinearProgress variant={progressValues[index].variant} value={100} color={progressValues[index].color} />
            </div>
        </div>
    ));

    return (
        <> 
            <div className='md:inline-flex'>
                <div className=''>
                    <button
                        type='button'
                        onClick={hideAddImages}
                        className='mt-4 py-2 px-4 bg-blue-500 text-white rounded-sm w-full md:w-auto hover:bg-blue-600 transition'
                    >Done Adding Images</button>
                </div>
            </div>
            <div>
                <div {...getRootProps({className: 'dropzone'})} className='mt-4 bg-gray-200 text-gray-700 text-center py-12 px-4 border-4 border-dashed border-gray-300 w-full'>
                    <input {...getInputProps()} />
                    <SaveAltIcon sx={{ fontSize: 36 }} />
                    <p className='mt-4'>Drag and Drop some files here, or click to select files</p>
                    <p className='mt-2 text-sm'>Supported Files: PNG, JPG/JPEG, MP4</p>
                </div>
                {uploadErrors.length > 0 && (
                    <div className='mt-4'>
                        {uploadErrors.map((error) => (
                            <p className='text-red-400'>{error}</p>
                        ))}
                    </div>
                )}
                {files.length > 0 && (
                    <div className='py-4 mt-4 w-full grid grid-cols-2 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-8 gap-4'>
                        {thumbs}
                    </div>
                )}
            </div>
        </>
    )
}

export default UploadMedia