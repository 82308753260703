import { useState } from "react";
import { useCreateTrip } from "../../hooks/trip/useCreateTrip";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const CreateTrip = () => {
    const { createTrip, isLoading, error } = useCreateTrip();

    const [tripName, setTripName] = useState('');
    const [tripIdentifier, setTripIdentifier] = useState('');
    const [tripStart, setTripStart] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();

        await createTrip(tripName, tripIdentifier, tripStart);
    }

    return (
        <div className='mt-4'>
            <form onSubmit={handleSubmit} className='bg-white p-4'>
                {error && (<div><p className='text-lg mb-4 text-red-400'>{error}</p></div>)}
                <div>
                    <p className='text-xl'>Location:</p>
                    <input
                        type='text'
                        className='w-full p-2 text-lg border border-gray-300 rounded-sm'
                        placeholder='e.g. Germany, Italy, Switzerland'
                        value={tripName}
                        onChange={(e) => setTripName(e.target.value)}
                    />
                </div>
                <div className='mt-4'>
                    <p className='text-xl'>Unique Identifier:</p>
                    <input
                        type='text'
                        className='w-full p-2 text-lg border border-gray-300 rounded-sm'
                        placeholder='e.g. Germany August 2023'
                        value={tripIdentifier}
                        onChange={(e) => setTripIdentifier(e.target.value)}
                    />
                </div>
                <div className='mt-4'>
                    <p className='text-xl'>Start Date:</p>
                    <DatePicker value={tripStart} onChange={(newValue) => setTripStart(newValue)} />
                </div>
                <div className='mt-4'>
                    <button
                        type='submit'
                        disabled={isLoading}
                        className='py-2 px-4 bg-blue-500 text-white text-lg font-bold hover:bg-blue-600 transition rounded-sm'
                    >Save</button>
                </div>
            </form>
        </div>
    )
}

export default CreateTrip