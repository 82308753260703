import { useState } from 'react';

import { useAuthContext } from '../useAuthContext';

export const useDeleteMultipleMedia = () => {
    const { user } = useAuthContext();

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const deleteMultipleMedia = async (mediaIds) => {
        setIsLoading(true);
        const response = await fetch('/api/media/delete', {
            method: 'DELETE',
            body: JSON.stringify({ mediaIds }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        });

        const json = await response.json();

        if (!response.ok) {
            setIsLoading(false);
            setError(json.error);

            return false;
        }

        if (response.ok) {
            setIsLoading(false);
            setError(null);

            return json;
        }
    }

    return { deleteMultipleMedia, isLoading, error }
}