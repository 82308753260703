// React Utilities
import { useEffect, useState } from 'react';

// Context / Hooks
import { useAuthContext } from '../../hooks/useAuthContext';
import { useResendVerificationEmail } from '../../hooks/email/useResendVerificationEmail';

const VerifyEmailPopup = () => {
    // Context / Hooks
    const { user } = useAuthContext();
    const { resendVerificationEmail, isLoading, error } = useResendVerificationEmail();
    
    // Local State
    const [isSent, setIsSent] = useState(false);

    const handleResendEmail = async () => {
        const sent = await resendVerificationEmail();

        if (sent) {
            setIsSent(true);
        }
    }

    useEffect(() => {
        const fetchVerification = async () => {
            const response = await fetch('/api/email/verify/check', {
                headers: { 'Authorization': `Bearer ${user.token} `}
            });
            const json = await response.json();

            if (response.ok) {
                if (json.emailVerified === true) {
                    document.getElementById('verify-email-popup').classList.add('hidden');
                    let userItem = JSON.parse(localStorage.getItem('tsb_user'));
                    userItem.verified = true;
                    localStorage.setItem('tsb_user', JSON.stringify(userItem));
                }
            }
        }

        fetchVerification();
    }, [user]);
    
    return (
        <div className='max-w-7xl mx-auto px-4 pt-4' id='verify-email-popup'>
            <div className='p-4 border rounded-sm border-yellow-300 bg-yellow-100'>
                {error && <div className='mb-4'><p className='text-center text-red-500'>{error}</p></div>}
                <div className='lg:flex lg:justify-between'>
                    <p className='text-center lg:text-left'>Check your email for a link from Trip Fern to verify your account.</p>
                    <div className='text-center lg:text-right mt-4 lg:mt-0'>
                        {isSent ? (
                            <p>Email Sent!</p>
                        ) : (
                            <button className='underline' disabled={isLoading} onClick={handleResendEmail}>
                                {isLoading ? 'Sending...' : 'Resend Email'}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VerifyEmailPopup