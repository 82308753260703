import { useState } from 'react';
import { Link } from 'react-router-dom';

import { useAuthContext } from '../../hooks/useAuthContext';

import LinearProgress from '@mui/material/LinearProgress';

const LocationsSearchBar = () => {
    const { user } = useAuthContext();

    const [searchQuery, setSearchQuery] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const [searchResults, setSearchResults] = useState([]);

    const handleSubmitSearch = async (event) => {
        event.preventDefault();

        setIsLoading(true);

        const response = await fetch(`/api/recommendation/location/search/${searchQuery}`, {
            headers: { 'Authorization': `Bearer ${user.token}`}
        });

        const json = await response.json();

        if (!response.ok) {
            setIsLoading(false);
            setError(json.error);
            
            setSearchResults(null);
        }

        if (response.ok) {
            setIsLoading(false);
            setError(null);

            setSearchResults(json);
        }
    }

    return (
        <div>
            <div>
                <form onSubmit={handleSubmitSearch}>
                    <div className='flex'>
                        <input 
                            type='text'
                            className='w-full p-2 border border-gray-300 rounded-sm'
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            placeholder='Enter a City/Town...'
                        />
                        <button
                            type='submit'
                            className='ml-2 py-2 px-4 bg-blue-500 hover:bg-blue-600 transition text-white rounded-sm'
                        >Search</button>
                    </div>
                </form>
            </div>
            {error && <p className='mt-4 text-red-400'>{error}</p>}
            {isLoading && (
                <div className='mt-4'>
                    <LinearProgress variant='indeterminate' />
                </div>
            )}
            {(searchResults.length > 0) && (
                <div className='mt-4'>
                    <p className='mb-2 font-bold'>Cities Results: {searchResults.length === 10 ? '10+' : searchResults.length}</p>
                    {searchResults.map((city) => (
                        <div className='mb-2' key={city.id}>
                            <Link 
                                to={`/recommendations/search/jsonid/${city.id}`}
                                className='underline hover:no-underline'
                            >{city.name}, {city.state_name}, {city.country_name}</Link>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

export default LocationsSearchBar