import { useAuthContext } from "../useAuthContext";

export const useLogout = () => {
    const { dispatch } = useAuthContext();

    const logout = () => {
        // Remove User Item from Local Storage
        localStorage.removeItem('tsb_user');

        // Dispatch Logout Action for Global State Management
        dispatch({type: 'LOGOUT'});
    }

    return { logout }
}