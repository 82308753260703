import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuthContext } from '../hooks/useAuthContext';

import dayjs from 'dayjs';

import UploadMedia from '../components/core/UploadMedia';
import TripMedia from '../components/core/TripMedia';
import TripActivities from '../components/core/TripActivities';
import EditTrip from '../components/core/EditTrip';

const Trip = () => {
    const { user } = useAuthContext();
    const { tripId } = useParams();

    const [trip, setTrip] = useState(null);
    const [tripName, setTripName] = useState('');
    const [tripStartDate, setTripStartDate] = useState('');
    const [tripIdentifier, setTripIdentifier] = useState('');
    const [tripActivities, setTripActivities] = useState([]);

    const [tripDataIsLoading, setTripDataIsLoading] = useState(false);

    const [media, setMedia] = useState([]);

    const [selectedActivityId, setSelectedActivityId] = useState('');

    const [showEdit, setShowEdit] = useState(false);
    const toggleShowEdit = () => { setShowEdit(!showEdit) }

    const [showUpload, setShowUpload] = useState(false);
    const toggleShowUpload = () => { setShowUpload(!showUpload) }

    useEffect(() => {
        const fetchTrip = async () => {
            setTripDataIsLoading(true);
            const response = await fetch(`/api/trip/one/${tripId}`, {
                headers: { 'Authorization': `Bearer ${user.token}`}
            });

            const json = await response.json();

            if (response.ok) {
                setTripDataIsLoading(false);

                setTrip(json);
                setTripName(json.name);
                setTripIdentifier(json.identifier);
                setTripActivities(json.activities);
                setTripStartDate(json.startDate);
            }

            if (!response.ok) {
                setTripDataIsLoading(false);
            }
        }

        if (user) {
            fetchTrip();
        }
    }, [tripId, user]);

    return (
        <div>
            <div className='sm:flex sm:justify-between sm:items-start'>
                <div>
                    <h2 className='text-6xl font-bold'>{tripName}</h2>
                    <h3 className='mt-4 text-xl italic'>
                        {tripStartDate && `${dayjs(tripStartDate).format('MM/DD/YYYY')} - `}{tripIdentifier}
                    </h3>
                    {(trip && trip.user.length === 1 && trip.user[0].email === user.email) && (
                        <>
                            <button type='button' onClick={toggleShowEdit} className='mt-2 underline'>Edit Date</button>
                            {showEdit && (
                                <EditTrip 
                                    tripId={tripId} 
                                    tripStartDate={tripStartDate} 
                                    setTripStartDate={setTripStartDate} 
                                    setShowEdit={setShowEdit}
                                />
                            )}
                        </>
                    )}
                </div>
                <button 
                    type='button'
                    onClick={toggleShowUpload} 
                    className='w-full mt-4 sm:mt-0 sm:w-auto py-2 px-4 bg-blue-500 hover:bg-blue-600 transition text-white rounded-sm font-bold text-lg'
                >Upload</button>
            </div>
            {showUpload && (
                <UploadMedia 
                    tripId={tripId} 
                    setShowUpload={setShowUpload}
                />
            )}
            <TripActivities 
                tripId={tripId} 
                media={media}
                activities={tripActivities} 
                selectedActivityId={selectedActivityId} 
                setSelectedActivityId={setSelectedActivityId} 
                tripDataIsLoading={tripDataIsLoading}
            />
            <TripMedia 
                media={media} 
                setMedia={setMedia} 
                tripId={tripId} 
                activities={tripActivities} 
                selectedActivityId={selectedActivityId}
            />
        </div>
    )
}

export default Trip