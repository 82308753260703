import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import { useAuthContext } from './hooks/useAuthContext';

// Pages
import Nav from './components/layout/Nav';
import Home from './pages/Home';
import Login from './pages/Login';
import About from './pages/About';
import Trip from './pages/Trip';
import Account from './pages/Account';
import Invitations from './pages/Invitations';
import Search from './pages/Search';
import LocationSearch from './pages/LocationSearch';
import Recommendations from './pages/Recommendations';
import BookmarkedRecommendations from './pages/BookmarkedRecommendations';
import Feedback from './pages/Feedback';
import VerifyEmail from './pages/VerifyEmail';
import ResetPassword from './pages/ResetPassword';

// Components
import VerifyEmailPopup from './components/email/VerifyEmailPopup';

function App() {
  const { user } = useAuthContext();

  return (
    <div className="App">
      <BrowserRouter>
        <Nav />
        {(user && !user.emailVerified) && <VerifyEmailPopup />}
        <div id='pages-container' className='max-w-7xl mx-auto p-4'>
          <Routes>
            {/* Public Routes */}
            <Route
              path='/about'
              element={<About />}
            />
            <Route
              path="/email/verify/:userId/:key"
              element={<VerifyEmail />}
            />
            <Route
              path='/email/reset/:userId/:key'
              element={<ResetPassword />}
            />
            <Route
              path='/login'
              element={!user ? <Login /> : <Navigate to='/' />}
            />

            {/* Protected Routes */}
            <Route
              path='/'
              element={user ? <Home /> : <Navigate to='/login' />}
            />
            <Route
              path='/account'
              element={user ? <Account /> : <Navigate to='/login' />}
            />
            <Route
              path='/invitations'
              element={user ? <Invitations /> : <Navigate to='/login' />}
            />
            <Route
              path='/trip/:tripId'
              element={user ? <Trip /> : <Navigate to='/login' />}
            />
            <Route
              path='/search/:searchQuery'
              element={user ? <Search /> : <Navigate to='/login' />}
            />
            <Route
              path='/search'
              element={user ? <Search /> : <Navigate to='/login' />}
            />
            <Route
              path='/location/search'
              element={user ? <LocationSearch /> : <Navigate to='/login' />}
            />
            <Route
              path='/recommendations/search/jsonid/:cityId'
              element={user ? <Recommendations /> : <Navigate to='/login' />}
            />
            <Route
              path='/recommendations/bookmarks'
              element={user ? <BookmarkedRecommendations /> : <Navigate to='/login' />}
            />
            <Route
              path='/feedback'
              element={user ? <Feedback /> : <Navigate to='/login' />}
            />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
