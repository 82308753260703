import { useState, useEffect } from 'react';

import { useParams } from "react-router-dom"

import { useAuthContext } from "../hooks/useAuthContext";

import SearchBar from "../components/core/SearchBar";

import LinearProgress from '@mui/material/LinearProgress';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';

const Search = () => {
    const { user } = useAuthContext();
    const { searchQuery } = useParams();
    
    const [searchIsLoading, setSearchIsLoading] = useState(false);
    const [searchError, setSearchError] = useState(null);

    const [searchResultsTrips, setSearchResultsTrips] = useState([]);
    const [searchResultsMedia, setSearchResultsMedia] = useState([]);
    const [searchResultsMediaFiltered, setSearchResultsMediaFiltered] = useState([]);
    const [searchResultsAdditionalTags, setSearchResultsAdditionalTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);

    const toggleSelectedTag = (tag) => {
        if (!selectedTags.includes(tag)) {
            const newSelectedTags = [tag, ...selectedTags];
            setSelectedTags(newSelectedTags);
        } else {
            const newSelectedTags = selectedTags.filter((st) => st !== tag);
            setSelectedTags(newSelectedTags);
        }
    }

    const handleDialogImageOpen = (media) => {

    }

    useEffect(() => {
        const fetchResults = async () => {
            setSearchIsLoading(true);

            const response = await fetch(`/api/search/${searchQuery}`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            });

            const json = await response.json();

            if (!response.ok) {
                setSearchIsLoading(false);
                setSearchError(json.error);

                setSearchResultsMedia([]);
            }

            if (response.ok) {
                setSearchIsLoading(false);
                setSearchError(null);

                setSearchResultsMedia(json.media);

                const tempAdditionalTags = [];
                for (let i = 0; i < json.media.length; i++) {
                    for (let j = 0; j < json.media[i].myUserTags.length; j++) {
                        tempAdditionalTags.push(json.media[i].myUserTags[j]);
                    }
                }

                const additionalTagsNoDupes = tempAdditionalTags.filter((tat, index) => tempAdditionalTags.indexOf(tat) === index);
                console.log(json.media);
                console.log('TAT:', tempAdditionalTags);
                console.log('No Dupes:', additionalTagsNoDupes);
                setSearchResultsAdditionalTags(additionalTagsNoDupes);
            }
        }

        if (user) {
            fetchResults();
        }
    }, [user, searchQuery]);
    
    useEffect(() => {
        let filteredResults = [];
        for (let i = 0; i < searchResultsMedia.length; i++) {
            let match = true;

            for (let j = 0; j < selectedTags.length; j++) {
                if (!searchResultsMedia[i].myUserTags.includes(selectedTags[j])) {
                    match = false;
                }
            }

            if (match) {
                filteredResults.push(searchResultsMedia[i]);
            }
        }

        setSearchResultsMediaFiltered(filteredResults);
    }, [selectedTags, searchResultsMedia]);
    return (
        <div>
            <SearchBar />
            <p>{searchResultsMedia.length} results for {searchQuery}</p>
            {searchIsLoading && <LinearProgress variant='indeterminate' />}
            {searchError && <p className='text-red-400 text-lg'>{searchError}</p>}
            {searchResultsAdditionalTags.length > 0 && (
                <div className='mt-2'>
                    <p>Filter by Additional Tags (click one or more to filter):</p>
                    <div className='flex gap-2'>
                        {searchResultsAdditionalTags.map((at) => (
                            <button 
                                type='button' 
                                className={(selectedTags.includes(at) ? 'font-bold' : '') + ` underline hover:no-underline`}
                                onClick={() => toggleSelectedTag(at)}
                            >{at}</button>
                        ))}
                    </div>
                </div>
            )}
            {searchResultsTrips.length > 0 && (
                <div>Stuff</div>
            )}
            {searchResultsMedia.length > 0 && (
                <div className='mt-4'>
                    <h3 className='text-2xl mb-2'>Media Results:</h3>
                    <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4'>
                        {selectedTags.length > 0 ? (
                            searchResultsMediaFiltered.map((srm) => (
                                <Card key={srm._id} className=''>
                                    <CardContent sx={{ padding: 0, height: '300px' }} onClick={() => handleDialogImageOpen(srm)}>
                                        <img src={srm.hasThumbnail ? srm.thumbnailUrl : srm.url} alt='' className='h-full w-full object-cover' />
                                    </CardContent>
                                </Card>
                            ))
                        ) : (
                            searchResultsMedia.map((srm) => (
                                <Card key={srm._id} className=''>
                                    <CardContent sx={{ padding: 0, height: '300px' }} onClick={() => handleDialogImageOpen(srm)}>
                                        <img src={srm.hasThumbnail ? srm.thumbnailUrl : srm.url} alt='' className='h-full w-full object-cover' />
                                    </CardContent>
                                </Card>
                            ))
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}

export default Search