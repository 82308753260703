import { useState } from 'react';
import { useAuthContext } from '../useAuthContext';

export const useSetMediaActivity = () => {
    const { user } = useAuthContext();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);

    const setMediaActivity = async (tripId, mediaIds, activityId) => {
        const response = await fetch('/api/media/activity/set', {
            method: 'PATCH',
            body: JSON.stringify({ tripId, mediaIds, activityId }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        });

        const json = await response.json();

        if (!response.ok) {
            setIsLoading(false);
            setError(json.error);

            return false;
        }

        if (response.ok) {
            setIsLoading(false);
            setError(null);

            return json;
        }
    }

    return { setMediaActivity, isLoading, error }
}