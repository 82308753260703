import { useEffect, useState } from "react";

import { Link, useParams } from "react-router-dom"

import { useAuthContext } from "../hooks/useAuthContext";

import CategoryRecommendations from "../components/recommendations/CategoryRecommendations";

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActionArea from '@mui/material/CardActionArea';
import LinearProgress from '@mui/material/LinearProgress';

import ThumbUpIcon from '@mui/icons-material/ThumbUp';

const Recommendations = () => {
    const { user } = useAuthContext();
    const { cityId } = useParams();

    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const [city, setCity] = useState(null);
    const [recommendations, setRecommendations] = useState(null);

    const [myVotes, setMyVotes] = useState([]);
    const [myBookmarks, setMyBookmarks] = useState([]);

    const [recsIndoor, setRecsIndoor] = useState([]);
    const [recsOutdoor, setRecsOutdoor] = useState([]);
    const [recsSights, setRecsSights] = useState([]);
    const [recsShopping, setRecsShopping] = useState([]);
    const [recsFood, setRecsFood] = useState([]);
    const [recsLodging, setRecsLodging] = useState([]);

    const [selectedCategory, setSelectedCategory] = useState('');
    const [showCategoryRecommendations, setShowCategoryRecommendations] = useState(false);

    const handleSelectCategory = (cat) => {
        setSelectedCategory(cat);
        setShowCategoryRecommendations(true);
    }

    useEffect(() => {
        const fetchCityData = async () => {
            setIsLoading(true);

            const response = await fetch(`/api/recommendation/location/city/id/${cityId}`, {
                headers: { 'Authorization': `Bearer ${user.token}` }
            });

            const json = await response.json();

            if (!response.ok) {
                setIsLoading(false);
                setError(json.error);
            }

            if (response.ok) {
                setIsLoading(false);
                setError(null);

                setCity(json.city);
                setRecommendations(json.recommendations);
            }
        }

        const fetchMyBookmarksAndVotes = async () => {
            const response = await fetch('/api/recommendation/data/bookmarksandvotes', {
                headers: { 'Authorization': `Bearer ${user.token}` }
            });

            const json = await response.json();

            if (!response.ok) {
                console.log('Error pulling user votes');
            }

            if (response.ok) {
                setMyVotes(json.votes);
                setMyBookmarks(json.bookmarks);
            }
        }

        if (!cityId) {
            setError('Please go back to Search, and select a City');        
        } else if (user) {
            fetchCityData();
            fetchMyBookmarksAndVotes();
        }
    }, [cityId]);
    return (
        <div>
            {error && <p>{error}</p>}
            {isLoading && (
                <div>
                    <LinearProgress variant='indeterminate' />
                </div>
            )}
            {city && (
                <div>
                    <div className='mb-4 flex'>
                        <Link to='/location/search' className='underline hover:no-underline'>Back to Search</Link>
                    </div>
                    <h2 className='text-2xl font-bold mb-2'>Recommendations for {city.name}</h2>
                    <p>City/Town in {city.state_name}, {city.country_name}</p>

                    {showCategoryRecommendations ? (
                        <CategoryRecommendations 
                            category={selectedCategory} 
                            recommendations={recommendations} 
                            setRecommendations={setRecommendations}
                            city={city}
                            myVotes={myVotes}
                            setMyVotes={setMyVotes}
                            myBookmarks={myBookmarks}
                            setMyBookmarks={setMyBookmarks}
                            setShowCategoryRecommendations={setShowCategoryRecommendations}
                        />
                    ) : (
                        <>
                            {recommendations && (
                                <div className='mt-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4'>
                                    <Card>
                                        <CardActionArea onClick={() => handleSelectCategory('indoor')}>
                                            <CardHeader 
                                                title={'Indoor Activities'} 
                                                subheader={
                                                    <div>
                                                        <p className='flex items-center'>
                                                            <ThumbUpIcon sx={{ fontSize: 18 }} />&nbsp;{recommendations.indoor.length} Recommendations (0 Votes)
                                                        </p>
                                                    </div>
                                                }
                                            />
                                        </CardActionArea>
                                    </Card>
                                    <Card>
                                        <CardActionArea onClick={() => handleSelectCategory('outdoor')}>
                                            <CardHeader 
                                                title={'Outdoor Activities'} 
                                                subheader={
                                                    <div>
                                                        <p className='flex items-center'>
                                                            <ThumbUpIcon sx={{ fontSize: 18 }} />&nbsp;{recommendations.outdoor.length} Recommendations (0 Votes)
                                                        </p>
                                                    </div>
                                                }
                                            />
                                        </CardActionArea>
                                    </Card>
                                    <Card>
                                        <CardActionArea onClick={() => handleSelectCategory('sights')}>
                                            <CardHeader 
                                                title={'Sightseeing'} 
                                                subheader={
                                                    <div>
                                                        <p className='flex items-center'>
                                                            <ThumbUpIcon sx={{ fontSize: 18 }} />&nbsp;{recommendations.sights.length} Recommendations (0 Votes)
                                                        </p>
                                                    </div>
                                                }
                                            />
                                        </CardActionArea>
                                    </Card>
                                    <Card>
                                        <CardActionArea onClick={() => handleSelectCategory('shopping')}>
                                            <CardHeader 
                                                title={'Shopping'} 
                                                subheader={
                                                    <div>
                                                        <p className='flex items-center'>
                                                            <ThumbUpIcon sx={{ fontSize: 18 }} />&nbsp;{recommendations.shopping.length} Recommendations (0 Votes)
                                                        </p>
                                                    </div>
                                                }
                                            />
                                        </CardActionArea>
                                    </Card>
                                    <Card>
                                        <CardActionArea onClick={() => handleSelectCategory('food')}>
                                            <CardHeader 
                                                title={'Food & Dining'} 
                                                subheader={
                                                    <div>
                                                        <p className='flex items-center'>
                                                            <ThumbUpIcon sx={{ fontSize: 18 }} />&nbsp;{recommendations.food.length} Recommendations (0 Votes)
                                                        </p>
                                                    </div>
                                                }
                                            />
                                        </CardActionArea>
                                    </Card>
                                    <Card>
                                        <CardActionArea onClick={() => handleSelectCategory('lodging')}>
                                            <CardHeader 
                                                title={'Lodging'} 
                                                subheader={
                                                    <div>
                                                        <p className='flex items-center'>
                                                            <ThumbUpIcon sx={{ fontSize: 18 }} />&nbsp;{recommendations.lodging.length} Recommendations (0 Votes)
                                                        </p>
                                                    </div>
                                                }
                                            />
                                        </CardActionArea>
                                    </Card>
                                </div>
                            )}
                        </>
                    )}
                </div>
            )}
        </div>
    )
}

export default Recommendations