import { useState } from 'react';

export const useSendForgotPasswordEmail = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    
    const sendForgotPasswordEmail = async (userEmail) => {
        setIsLoading(true);

        const response = await fetch('/api/email/reset/send', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: userEmail })
        });

        const json = await response.json();

        if (!response.ok) {
            setIsLoading(false);
            setError(json.error);

            return false;
        }

        if (response.ok) {
            setIsLoading(false);
            setError(null);

            return true;
        }
    }

    return { sendForgotPasswordEmail, isLoading, error }
}