// React Utilities
import { useState, useEffect } from 'react';

// Hooks
import { useLogin } from '../hooks/auth/useLogin';
import { useSignup } from '../hooks/auth/useSignup';
import { useSendForgotPasswordEmail } from '../hooks/email/useSendForgotPasswordEmail';

// Components
import PricingTable from '../components/pricing/PricingTable';

// MUI Components
import Skeleton from '@mui/material/Skeleton';

// MUI Icons
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

const Login = () => {
    const [emailLogin, setEmailLogin] = useState('');
    const [emailSignup, setEmailSignup] = useState('');
    const [passwordLogin, setPasswordLogin] = useState('');
    const [passwordSignup, setPasswordSignup] = useState('');
    const [displayNameSignup, setDisplayNameSignup] = useState('');

    const [accountType, setAccountType] = useState('free');
    const [accountTypeSelected, setAccountTypeSelected] = useState(false);

    const [showLogin, setShowLogin] = useState(true);
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const [forgotPasswordEmailSent, setForgotPasswordEmailSent] = useState(false);

    const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');

    const [expired, setExpired] = useState(false);

    const [statsLoading, setStatsLoading] = useState(false);

    // Login Page Stats States
    const [uC, setUC] = useState(0);
    const [tC, setTC] = useState(0);
    const [mC, setMC] = useState(0);
    const [rC, setRC] = useState(0);

    // Grab Functions and States from Hooks
    const { login, isLoading:isLoadingLogin, error:errorLogin } = useLogin();
    const { signup, isLoading:isLoadingSignup, error:errorSignup } = useSignup();
    const { sendForgotPasswordEmail, isLoading:resetPasswordIsLoading, error:resetPasswordError } = useSendForgotPasswordEmail();

    // Check if Session Expired and to Display Error
    useEffect(() => {
        const expiry = JSON.parse(localStorage.getItem('tsb_expired'));
        const expired = expiry ? expiry.expired : false;
        setExpired(expired);
        localStorage.removeItem('tsb_expired');
    }, []);

    // Login Form Submission
    const handleSubmitLogin = async (event) => {
        event.preventDefault();

        await login(emailLogin, passwordLogin);     
    }

    // Signup Form Submission
    const handleSubmitSignup = async (event) => {
        event.preventDefault();

        const signupResult = await signup(displayNameSignup, emailSignup, passwordSignup);
        
        if (signupResult && (accountType === 'travel' || accountType === 'adventure')) {
            handleSubmitUpgrade();
        }
    }

    const handleSubmitUpgrade = () => {
        document.getElementById('stripe-upgrade-form').submit();
    }

    const handleSubmitForgotPassword = async (event) => {
        event.preventDefault();

        const forgotPasswordSent = await sendForgotPasswordEmail(forgotPasswordEmail);

        if (forgotPasswordSent) {
            // Show Success Message
            setForgotPasswordEmailSent(true);
            setForgotPasswordEmail('');
        }
    }

    // Show / Hide Login & Signup Forms
    const toggleLoginSignup = () => {
        setShowLogin(!showLogin);
    }

    useEffect(() => {
        if (accountTypeSelected) {
            document.getElementById('signup-form').scrollIntoView({ behavior: 'smooth' });
        }
    }, [accountTypeSelected]);

    useEffect(() => {
        const fetchLoginPageStats = async () => {
            setStatsLoading(true);

            const response = await fetch('/api/public/stats/login');
            const json = await response.json();

            if (!response.ok) {
                console.log('Error fetching site stats');
            }

            if (!response.ok) {
                setStatsLoading(false);
            }

            if (response.ok) {
                setStatsLoading(false);
                setUC(json.uC);
                setTC(json.tC);
                setMC(json.mC);
                setRC(json.rC);
            }
        }

        fetchLoginPageStats();
        console.log('Running');
    }, []);

    return (
        <div>
            <h2 className='mt-8 text-6xl md:text-8xl text-center font-bold'>Travel Better</h2>
            <div className='mt-4 md:mt-12 text-center text-xl md:text-2xl'>
                {statsLoading ? (
                    <div className='w-full sm:w-[400px] mx-auto'>
                        <Skeleton variant={'rounded'} height={115} />
                    </div>
                ) : (
                    <>
                        <p><span className='font-bold'>{uC}</span> Travelers</p>
                        <p className='mt-2'><span className='font-bold'>{tC}</span> Trips <ArrowRightAltIcon sx={{ fontSize: 32 }}/> <span className='font-bold'>{mC}</span> Uploads</p>
                        <p className='mt-2'><span className='font-bold'>{rC}</span> Local Recommendations</p>
                    </>
                )}
            </div>
            {showForgotPassword ? (
                <form onSubmit={handleSubmitForgotPassword} className='w-full md:w-[400px] mx-auto mt-4 md:mt-12 p-8 bg-white rounded-md'>
                    <div>
                        <h3 className='font-bold text-lg'>Reset Your Password</h3>
                    </div>
                    {forgotPasswordEmailSent && (
                        <div className='mt-4'>
                            <p className='text-green-400'>If there is an email associated to that address, we'll send a Password Reset Link to your inbox.</p>
                        </div>
                    )}
                    {resetPasswordError && (
                        <div className='mt-4'>
                            <p className='text-red-400'>{resetPasswordError}</p>
                        </div>
                    )}
                    <div className='mt-4'>
                        <p>Email:</p>
                        <input
                            type='email'
                            onChange={(e) => setForgotPasswordEmail(e.target.value)}
                            value={forgotPasswordEmail}
                            className='w-full p-2 text-lg border border-gray-300'
                        />
                    </div>
                    <div className='mt-4'>
                        <button
                            type='submit'
                            className='py-2 px-4 bg-blue-500 hover:bg-blue-600 transition text-white font-bold w-full'
                        >Send Password Reset Link</button>
                    </div>
                </form>
            ) : (
                showLogin ? (
                    <form onSubmit={handleSubmitLogin} className='w-full md:w-[400px] mx-auto mt-4 md:mt-12 p-8 bg-white rounded-md'>
                        {expired && (<p className='p-2 rounded-md mb-4 bg-yellow-200 border-yellow-300 border'>Session Expired. Please log back in.</p>)}
                        {errorLogin && <p className='text-red-500 border-red-600 bg-red-100 rounded-md border p-2 mb-4'>{errorLogin}</p>}
                        <div>
                            <h3 className='font-bold text-lg'>Login to Your Account</h3>
                        </div>
                        <div className='mt-4'>
                            <p>Email:</p>
                            <input
                                type='email'
                                onChange={(e) => setEmailLogin(e.target.value)}
                                value={emailLogin}
                                className='w-full p-2 text-lg border border-gray-300'
                            />
                        </div>
                        <div className='mt-4'>
                            <p>Password:</p>
                            <input
                                type='password'
                                onChange={(e) => setPasswordLogin(e.target.value)}
                                value={passwordLogin}
                                className='w-full p-2 text-lg border border-gray-300'
                            />
                        </div>
                        <div className='mt-4'>
                            <button 
                                type='submit' 
                                disabled={isLoadingLogin} 
                                className='py-2 px-4 bg-blue-500 text-white w-full'
                            >Login</button>
                        </div>
                        <div className='mt-2'>
                            <button 
                                onClick={toggleLoginSignup}
                                type='button' 
                                className='py-2 px-4 border-2 border-gray-300 w-full'
                            >Create an Account</button>
                        </div>
                        <div className='mt-4 text-center'>
                            <button 
                                type='button'
                                className='text-sm underline hover:no-underline'
                                onClick={() => setShowForgotPassword(true)}
                            >Forgot Your Password?</button>
                        </div>
                    </form>
                ) : (
                    <>
                    <PricingTable accountType={accountType} setAccountType={setAccountType} setAccountTypeSelected={setAccountTypeSelected} />
                    <form action='/api/stripe/upgrade' id='stripe-upgrade-form' method='POST'>
                        <input type='hidden' name='accountType' value={accountType} />
                        <input type='hidden' name='accountEmail' value={emailSignup} />
                    </form>
                    <form onSubmit={handleSubmitSignup} id='signup-form' className='w-full md:w-[400px] mx-auto mb-64 mt-4 md:mt-12 p-8 bg-white rounded-md'>
                        {errorSignup && <p className='text-red-500 border-red-600 bg-red-100 rounded-md border p-2 mb-4'>{errorSignup}</p>}
                        <div>
                            <h3 className='font-bold text-lg'>Create an Account</h3>
                        </div>
                        <div className='mt-4'>
                            <p>Display Name:</p>
                            <input 
                                type='text'
                                value={displayNameSignup}
                                onChange={(e) => setDisplayNameSignup(e.target.value)}
                                className='w-full p-2 text-lg border border-gray-300'
                            />
                        </div>
                        <div className='mt-4'>
                            <p>Email:</p>
                            <input
                                type='email'
                                onChange={(e) => setEmailSignup(e.target.value)}
                                value={emailSignup}
                                className='w-full p-2 text-lg border border-gray-300'
                            />
                        </div>
                        <div className='mt-4'>
                            <p>Password:</p>
                            <input
                                type='password'
                                onChange={(e) => setPasswordSignup(e.target.value)}
                                value={passwordSignup}
                                className='w-full p-2 text-lg border border-gray-300'
                            />
                        </div>
                        <div className='mt-4'>
                            <p>Plan: {accountType === 'free' && 'Free'}{accountType === 'travel' && 'Travel'}{accountType === 'adventure' && 'Adventure'}</p>
                        </div>
                        <div className='mt-4'>
                            <button 
                                type='submit' 
                                disabled={isLoadingSignup} 
                                className='py-2 px-4 bg-blue-500 text-white w-full'
                            >Sign Up</button>
                        </div>
                        <div className='mt-2'>
                            <button 
                                type='button' 
                                onClick={toggleLoginSignup}
                                className='py-2 px-4 border-2 border-gray-300 w-full'
                            >Login to My Account</button>
                        </div>
                    </form>
                    </>
                )
            )}
        </div>
    )
}

export default Login