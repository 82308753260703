import { useState } from 'react';

import { useAuthContext } from '../useAuthContext';
import { useInvitationContext } from '../useInvitationContext';

export const useSendInvitationResponse = () => {
    const { user } = useAuthContext();
    const { dispatch } = useInvitationContext();

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const sendInvitationResponse = async (res, invitationId) => {
        const response = await fetch(`/api/invitation/update/${invitationId}`, {
            method: 'PATCH',
            body: JSON.stringify({ res }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        });

        const json = await response.json();

        if (!response.ok) {
            setIsLoading(false);
            setError(json.error);

            console.log(json.error);
        }

        if (response.ok) {
            setIsLoading(false);
            setError(null);

            dispatch({ type: 'UPDATE_INVITATION', payload: json });
            console.log('Invitation Responded & Updated');
        }
    }

    return { sendInvitationResponse, isLoading, error }
}