import MyTrips from '../components/core/MyTrips';
import SearchBar from '../components/core/SearchBar';

const Home = () => {
    return (
        <div>
            <h2 className='text-5xl sm:text-6xl font-bold mt-8'>Welcome, Adventurer!</h2>
            <SearchBar />
            <MyTrips />
        </div>
    )
}

export default Home