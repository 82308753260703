import { useState, useEffect } from 'react';

import { useAuthContext } from '../hooks/useAuthContext';
import { useAddBookmarkToTrip } from '../hooks/recommendation/useAddBookmarkToTrip';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import LinearProgress from '@mui/material/LinearProgress';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';

const BookmarkedRecommendations = () => {
    const { user } = useAuthContext();

    const [bookmarks, setBookmarks] = useState([]);
    const [bookmarksCities, setBookmarksCities] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const [myTrips, setMyTrips] = useState([]);
    const [errorTrips, setErrorTrips] = useState(null);

    const [selectedTrip, setSelectedTrip] = useState('');
    const [selectedBookmark, setSelectedBookmark] = useState(null);

    const [addToTripDialogOpen, setAddToTripDialogOpen] = useState(false);
    
    const { 
        addBookmarkToTrip, 
        isLoading: addBookmarkToTripIsLoading, 
        error: addBookmarkToTripError,
        setError: setAddBookmarkToTripError,
    } = useAddBookmarkToTrip();

    const handleAddToTripDialogOpen = (bookmark) => { 
        if (bookmark.recommendation[0]) {
            setSelectedBookmark(bookmark.recommendation[0].name);
            setAddToTripDialogOpen(true) 
        }
    }

    const handleAddToTripDialogClose = () => { 
        setAddToTripDialogOpen(false);
        setAddBookmarkToTripError(null); 
    }

    const handleAddToTrip = async () => {
        const updatedTrip = await addBookmarkToTrip(selectedBookmark, selectedTrip);
        
        if (updatedTrip) {
            setMyTrips(myTrips.map((t) => t._id === updatedTrip._id ? updatedTrip : t));
            handleAddToTripDialogClose();
            handleSnackbarOpen();
        }
    }

    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const handleSnackbarOpen = () => { setSnackbarOpen(true) }
    const handleSnackbarClose = () => { setSnackbarOpen(false) }

    useEffect(() => {
        const fetchBookmarks = async () => {
            setIsLoading(true);

            const response = await fetch('/api/recommendation/bookmark/my', {
                headers: { 'Authorization': `Bearer ${user.token}` }
            });

            const json = await response.json();

            if (!response.ok) {
                setIsLoading(false);
                setError(json.error);
            }

            if (response.ok) {
                setIsLoading(false);
                setError(null);

                setBookmarks(json);

                const newBookmarksCitiesAll = json.map((b) => {
                    return (
                        b.recommendation[0].cityNameDisplay + ', ' + 
                        b.recommendation[0].cityStateNameDisplay + ', ' + 
                        b.recommendation[0].cityCountryNameDisplay
                    )
                });
                const newBookmarksCitiesUniques = newBookmarksCitiesAll.filter((nbca, index) => newBookmarksCitiesAll.indexOf(nbca) === index);
                setBookmarksCities(newBookmarksCitiesUniques);
            }
        }

        const fetchTrips = async () => {
            const response = await fetch('/api/trip/all', {
                headers: { 'Authorization': `Bearer ${user.token}` }
            });

            const json = await response.json();

            if (!response.ok) {
                setErrorTrips('Error fetching your trips. Please try again later.');
            }

            if (response.ok) {
                setMyTrips(json);
            }
        }

        if (user) {
            fetchBookmarks();
            fetchTrips();
        }
    }, [user]);

    return (
        <div>
            <h2 className='text-2xl font-bold'>My Bookmarked Recommendations</h2>
            {isLoading && <div className='mt-4'><LinearProgress variant='indeterminate' /></div>}
            {bookmarksCities.length > 0 && (
                bookmarksCities.map((bc, index) => (
                    <div key={index} className='mt-4'>
                        <h3 className='text-xl mb-2'>{bc}</h3>
                        {bookmarks.filter((b) => bc === b.recommendation[0].cityNameDisplay + ', ' + b.recommendation[0].cityStateNameDisplay + ', ' + b.recommendation[0].cityCountryNameDisplay).map((b, index) => (
                            b.recommendation[0] && (
                                <div key={index} className='mb-4 bg-white p-4 rounded-sm'>
                                    <div>
                                        <p className='font-bold text-xl'>{b.recommendation[0].name}</p>
                                    </div>
                                    <div className='mt-4'>
                                        {(myTrips.find((t) => t.activities.find((a) => a.name === b.recommendation[0].name))) ? (
                                            <>
                                                <button 
                                                    type='button'
                                                    onClick={() => handleAddToTripDialogOpen(b)}
                                                    className='flex items-center py-1 pl-2 pr-3 text-sm border border-blue-500 text-blue-500 rounded-sm'
                                                ><CheckIcon />&nbsp;Added to Trip</button>
                                                <p className='mt-4 text-xs'>This activity is in your trips:</p>
                                                {myTrips.filter((t) => t.activities.find((a) => a.name === b.recommendation[0].name)).map((t) => (
                                                    <p key={t._id} className='text-xs'>- {t.identifier}</p>
                                                ))}
                                            </>
                                        ) : (
                                            <button 
                                                type='button'
                                                onClick={() => handleAddToTripDialogOpen(b)}
                                                className='flex items-center py-1 pl-2 pr-3 text-sm border border-gray-300 rounded-sm'
                                            ><AddIcon />&nbsp;Add to Trip</button>
                                        )}
                                    </div>
                                </div>
                            )
                        ))}
                    </div>
                ))
            )}
            <Dialog
                open={addToTripDialogOpen}
                onClose={handleAddToTripDialogClose}
                aria-labelledby='add-to-trip-dialog-title'
                aria-describedby='add-to-trip-dialog-description'
            >
                <DialogTitle id='add-to-trip-dialog-title'>
                    {'Add Activity to Trip'}
                </DialogTitle>
                <DialogContent>
                    {errorTrips ? (
                        <p className='text-red-400 text-lg'>{errorTrips}</p>
                    ) : (
                        <>
                            {addBookmarkToTripError && (
                                <div className='mb-4'>
                                    <DialogContentText color='error'>
                                        {addBookmarkToTripError}
                                    </DialogContentText>
                                </div>
                            )}
                            <DialogContentText id='add-to-trip-dialog-description'>
                                Choose a Trip to add this Activity to:
                            </DialogContentText>
                            {myTrips && (
                                <div className='mt-2'>
                                    <select 
                                        value={selectedTrip} 
                                        onChange={(e) => setSelectedTrip(e.target.value)}
                                        className='p-2 w-full border border-gray-300'
                                    >
                                        <option value=''>Select a Trip</option>
                                        {myTrips.map((t) => (
                                            <option key={t._id} value={t._id}>{t.identifier}</option>
                                        ))}
                                    </select>
                                </div>
                            )}
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAddToTripDialogClose} color='error'>Cancel</Button>
                    <Button onClick={handleAddToTrip} color='primary'>Add</Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={snackbarOpen} autoHideDuration={5000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={'success'} variant={'filled'} sx={{ width: '100%' }}>
                    Activity Added to Trip!
                </Alert>
            </Snackbar>
        </div>
    )
}

export default BookmarkedRecommendations