import { useAuthContext } from "../useAuthContext";

export const useChangeName = () => {
    const { user } = useAuthContext();

    const changeName = async (newName) => {
        const response = await fetch('/api/user/name', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            body: JSON.stringify({ newName })
        });
        const json = await response.json();

        if (!response.ok) {
            return false;
        }

        if (response.ok) {
            return json;
        }
    }

    return { changeName }
}