// React Utilities
import { useState } from 'react';

// Hooks
import { useAddVote } from '../../hooks/recommendation/useAddVote';
import { useAddBookmark } from '../../hooks/recommendation/useAddBookmark';
import { useAddRecommendation } from '../../hooks/recommendation/useAddRecommendation';

// MUI Components
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';

// MUI Icons
import RecommendIcon from '@mui/icons-material/Recommend';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';

const categoryDisplay = {
    indoor: 'Indoor Activities',
    outdoor: 'Outdoor Activities',
    sights: 'Sightseeing',
    shopping: 'Shopping',
    food: 'Food & Dining',
    lodging: 'Lodging'
}

const sortRecommendationsByVotesCount = (x, y) => {
    if (x.votes.length > y.votes.length) {
        return -1;
    } else if (x.votes.length < y.votes.length) {
        return 1;
    } else {
        return 0;
    }
}

const CategoryRecommendations = ({
    category, 
    city, 
    recommendations, 
    setRecommendations, 
    myVotes, 
    setMyVotes,
    myBookmarks,
    setMyBookmarks, 
    setShowCategoryRecommendations
}) => {
    const [showAddRecommendation, setShowAddRecommendation] = useState(false);

    const [recommendationName, setRecommendationName] = useState('');
    const [recommendationCost, setRecommendationCost] = useState('');
    const [recommendationCategory, setRecommendationCategory] = useState(category);

    const [addRecommendationSuccess, setAddRecommendationSuccess] = useState(false);

    const [voteRecommendationId, setVoteRecommendationId] = useState('');

    const { addRecommendation, isLoading, error } = useAddRecommendation();
    const { addVote, isLoading: voteIsLoading, error: voteError } = useAddVote();
    const { addBookmark, isLoading: bookmarkIsLoading, error: bookmarkError } = useAddBookmark();

    const handleShowAddRecommendation = () => {
        setShowAddRecommendation(true);
    }

    const handleAddRecommendationSubmit = async (event) => {
        event.preventDefault();

        // Build out useAddRecommendation Hook and Server Functions
        const addRecResult = await addRecommendation(recommendationName, recommendationCategory, recommendationCost, city);

        if (addRecResult) {
            // Update Recommendations State
            const newRecsObj = {
                indoor: recommendationCategory === 'indoor' ? [...recommendations.indoor, addRecResult] : recommendations.indoor,
                outdoor: recommendationCategory === 'outdoor' ? [...recommendations.outdoor, addRecResult] : recommendations.outdoor,
                sights: recommendationCategory === 'sights' ? [...recommendations.sights, addRecResult] : recommendations.sights,
                shopping: recommendationCategory === 'shopping' ? [...recommendations.shopping, addRecResult] : recommendations.shopping,
                food: recommendationCategory === 'food' ? [...recommendations.food, addRecResult] : recommendations.food,
                lodging: recommendationCategory === 'lodging' ? [...recommendations.lodging, addRecResult] : recommendations.lodging
            };
            setRecommendations(newRecsObj);
            
            // Reset Form Data
            setRecommendationName('');
            setRecommendationCost('');
            setRecommendationCategory(category);
            
            // Show Success Message
            setAddRecommendationSuccess(true);
        }
    }

    const handleVote = async (recommendationId) => {
        setVoteRecommendationId(recommendationId);
        const voteData = await addVote(recommendationId);

        if (voteData) {
            if (voteData.addOrRemove === 'add') {
                setMyVotes([...myVotes, voteData.newVote]);
                const recToReplace = recommendations[category].find((r) => r._id === voteData.newVote.recommendationId);
                const replacementVotes = [...recToReplace.votes, voteData.newVote];
                const replacementRec = {
                    ...recToReplace,
                    votes: replacementVotes
                };
                setRecommendations({
                    indoor: replacementRec.category === 'indoor' ? recommendations.indoor.map((ri) => ri._id === replacementRec._id ? replacementRec : ri).sort(sortRecommendationsByVotesCount) : recommendations.indoor,
                    outdoor: replacementRec.category === 'outdoor' ? recommendations.outdoor.map((ri) => ri._id === replacementRec._id ? replacementRec : ri).sort(sortRecommendationsByVotesCount) : recommendations.outdoor,
                    sights: replacementRec.category === 'sights' ? recommendations.sights.map((ri) => ri._id === replacementRec._id ? replacementRec : ri).sort(sortRecommendationsByVotesCount) : recommendations.sights,
                    shopping: replacementRec.category === 'shopping' ? recommendations.shopping.map((ri) => ri._id === replacementRec._id ? replacementRec : ri).sort(sortRecommendationsByVotesCount) : recommendations.shopping,
                    food: replacementRec.category === 'food' ? recommendations.food.map((ri) => ri._id === replacementRec._id ? replacementRec : ri).sort(sortRecommendationsByVotesCount) : recommendations.food,
                    lodging: replacementRec.category === 'lodging' ? recommendations.lodging.map((ri) => ri._id === replacementRec._id ? replacementRec : ri).sort(sortRecommendationsByVotesCount) : recommendations.lodging
                });
            } else if (voteData.addOrRemove === 'remove') {
                setMyVotes(myVotes.filter((v) => v._id !== voteData.newVote._id));
                const recToReplace = recommendations[category].find((r) => r._id === voteData.newVote.recommendationId);
                const replacementVotes = recToReplace.votes.filter((v) => v._id !== voteData.newVote._id);
                const replacementRec = {
                    ...recToReplace,
                    votes: replacementVotes
                };
                setRecommendations({
                    indoor: replacementRec.category === 'indoor' ? recommendations.indoor.map((ri) => ri._id === replacementRec._id ? replacementRec : ri).sort(sortRecommendationsByVotesCount) : recommendations.indoor,
                    outdoor: replacementRec.category === 'outdoor' ? recommendations.outdoor.map((ri) => ri._id === replacementRec._id ? replacementRec : ri).sort(sortRecommendationsByVotesCount) : recommendations.outdoor,
                    sights: replacementRec.category === 'sights' ? recommendations.sights.map((ri) => ri._id === replacementRec._id ? replacementRec : ri).sort(sortRecommendationsByVotesCount) : recommendations.sights,
                    shopping: replacementRec.category === 'shopping' ? recommendations.shopping.map((ri) => ri._id === replacementRec._id ? replacementRec : ri).sort(sortRecommendationsByVotesCount) : recommendations.shopping,
                    food: replacementRec.category === 'food' ? recommendations.food.map((ri) => ri._id === replacementRec._id ? replacementRec : ri).sort(sortRecommendationsByVotesCount) : recommendations.food,
                    lodging: replacementRec.category === 'lodging' ? recommendations.lodging.map((ri) => ri._id === replacementRec._id ? replacementRec : ri).sort(sortRecommendationsByVotesCount) : recommendations.lodging
                });
            } else {
                return;
            }
        }
    }

    const handleBookmark = async (recommendationId) => {
        const bookmarkData = await addBookmark(recommendationId);

        if (bookmarkData) {
            if (bookmarkData.addOrRemove === 'add') {
                setMyBookmarks([...myBookmarks, bookmarkData.newBookmark]);
            } else if (bookmarkData.addOrRemove === 'remove') {
                setMyBookmarks(myBookmarks.filter((b) => b._id !== bookmarkData.newBookmark._id));
            } else {
                return;
            }
        }
    }

    return (
        <>
            {showAddRecommendation ? (
                <div className='mt-4 bg-white p-2 sm:p-4 rounded-sm'>
                    <div>
                        <button
                            type='button'
                            className='underline hover:no-underline'
                            onClick={() => setShowAddRecommendation(false)}
                        >Go Back</button>
                    </div>
                    <div className='mt-4'>
                        <h3 className='text-xl font-bold'>Add Recommendation</h3>
                        {isLoading && (
                            <div className='mt-4'>
                                <LinearProgress variant='indeterminate' />
                            </div>
                        )}
                        {error && (
                            <div className='mt-4'>
                                <p className='text-red-400'>{error}</p>
                            </div>
                        )}
                        {addRecommendationSuccess ? (
                            <div className='mt-4'>
                                <p className='text-lg text-green-400'>Success! Recommendation Added!</p>
                                <div className='mt-4'>
                                    <button
                                        type='button'
                                        className='underline hover:no-underline'
                                        onClick={() => setShowAddRecommendation(false)}
                                    >Go Back to Recommendations</button>
                                </div>
                                <div className='mt-4'>
                                    <button
                                        type='button'
                                        className='underline hover:no-underline'
                                        onClick={() => setAddRecommendationSuccess(false)}
                                    >Add Another</button>
                                </div>
                            </div>
                        ) : (
                            <form onSubmit={handleAddRecommendationSubmit}>
                                <div className='mt-4'>
                                    <p>Recommendation Name:</p>
                                    <input
                                        type='text'
                                        className='p-2 w-full text-lg rounded-sm border border-gray-300'
                                        placeholder='Ex. Zugspitze, Six Flags, etc'
                                        value={recommendationName}
                                        onChange={(e) => setRecommendationName(e.target.value)}
                                    />
                                </div>
                                <div className='mt-4'>
                                    <p>Recommendation Category:</p>
                                    <select 
                                        value={recommendationCategory} 
                                        onChange={(e) => setRecommendationCategory(e.target.value)}
                                        className='p-2 w-full border border-gray-300 rounded-sm text-lg'
                                    >
                                        <option value=''>Select a Category</option>
                                        <option value='indoor'>Indoor Activities</option>
                                        <option value='outdoor'>Outdoor Activities</option>
                                        <option value='sights'>Sightseeing</option>
                                        <option value='shopping'>Shopping</option>
                                        <option value='food'>Food & Dining</option>
                                        <option value='lodging'>Lodging</option>
                                    </select>
                                </div>
                                <div className='mt-4'>
                                    <p>Cost:</p>
                                    <RadioGroup
                                        name='recommendations-cost-radio-group'
                                        value={recommendationCost}
                                        onChange={(e) => setRecommendationCost(e.target.value)}
                                    >
                                        <FormControlLabel value='0' control={<Radio />} label={<MoneyOffIcon />} />
                                        <FormControlLabel value='1' control={<Radio />} label={<AttachMoneyIcon />} />
                                        <FormControlLabel value='2' control={<Radio />} label={<><AttachMoneyIcon /><AttachMoneyIcon /></>} />
                                        <FormControlLabel value='3' control={<Radio />} label={<><AttachMoneyIcon /><AttachMoneyIcon /><AttachMoneyIcon /></>} />
                                    </RadioGroup>
                                </div>
                                <div className='mt-4'>
                                    <button
                                        type='submit'
                                        className='w-full sm:w-auto py-2 px-4 bg-blue-500 hover:bg-blue-600 transition text-white font-bold rounded-sm'
                                    >Submit Recommendation</button>
                                </div>
                            </form>
                        )}
                    </div>
                </div>
            ) : (
                <div className='mt-4 bg-white p-2 sm:p-4 rounded-sm'>
                    <div className='flex justify-between items-start'>
                        <button
                            type='button'
                            className='underline hover:no-underline'
                            onClick={() => setShowCategoryRecommendations(false)}
                        >Go Back</button>
                        <button
                            type='button'
                            onClick={handleShowAddRecommendation}
                            className='justify-center flex items-center text-sm py-1 px-2 bg-blue-500 hover:bg-blue-600 transition text-white font-bold rounded-sm'
                        ><RecommendIcon sx={{ fontSize: 18 }}/>&nbsp;Add Recommendation</button>
                    </div>
                    <h3 className='mt-4 text-xl font-bold'>Recommended {categoryDisplay[category]} for {city.name}</h3>
                    {(recommendations[category]) && (
                        <>
                        {recommendations[category].length > 0 ? (
                            <div className='mt-4 border-b border-gray-300'>
                                {recommendations[category].map((rec) => (
                                    <div key={rec._id} className='py-4 border-t border-gray-300'>
                                        <div className='flex justify-start gap-4 items-start'>
                                            <div className='w-32 text-center'>
                                                {rec.votes ? (
                                                    <>
                                                        <p className='text-xl'>{rec.votes.length}</p>
                                                        <p className='text-sm'>Votes</p>
                                                        <div className='mt-1'>
                                                            <button
                                                                type='button'
                                                                disabled={voteIsLoading}
                                                                onClick={() => handleVote(rec._id)}
                                                            >
                                                                {(voteIsLoading && rec._id === voteRecommendationId) ? (
                                                                    <div className='h-10'>
                                                                        <CircularProgress size={'2rem'} variant='indeterminate' />
                                                                    </div>
                                                                ) : (
                                                                    <div className='h-10'>
                                                                        <ThumbUpIcon 
                                                                            color={
                                                                                myVotes.find((vote) => vote.recommendationId === rec._id) ? 'primary' : 'action'
                                                                            } 
                                                                        />
                                                                    </div>
                                                                )}
                                                            </button>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <p className='text-xl'>0</p>
                                                        <p className='text-sm'>Votes</p>
                                                        <div className='mt-1'>
                                                            <button
                                                                type='button'
                                                                onClick={() => handleVote(rec._id)}
                                                            ><ThumbUpIcon color='action' /></button>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                            <div className='w-full h-full flex flex-col justify-between'>
                                                <h4 className='text-lg'>{rec.name}</h4>
                                                <div className='mt-4'>
                                                    <button 
                                                        onClick={() => handleBookmark(rec._id)} 
                                                        className={
                                                            (
                                                                myBookmarks.find((b) => b.recommendationId === rec._id) 
                                                                    ? 'border-blue-500 text-blue-500'
                                                                    : 'border-gray-300'
                                                            ) + ' py-1 px-2 border rounded-sm text-xs'
                                                        }
                                                    >
                                                        {myBookmarks.find((b) => b.recommendationId === rec._id) ? (
                                                            <>
                                                                <BookmarkIcon color={'primary'} sx={{ fontSize: 18 }}/>
                                                                {' Bookmarked'}
                                                            </> 
                                                        ) : (
                                                            <>
                                                                <BookmarkBorderIcon color={'action'} sx={{ fontSize: 18 }}/>
                                                                {' Bookmark this'}
                                                            </>
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className='mt-4'>
                                <p>There are no recommendations.</p>
                                <button 
                                    type='button'
                                    className='underline'
                                    onClick={handleShowAddRecommendation}
                                >Add a Recommendation</button>
                            </div>
                        )}
                        </>
                    )}
                </div>
            )}
        </>
    )
}

export default CategoryRecommendations