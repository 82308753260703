import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const SearchBar = () => {
    const navigate = useNavigate();

    const [searchQuery, setSearchQuery] = useState('');

    const handleSearchSubmit = (event) => {
        event.preventDefault();

        navigate(`/search/${searchQuery}`);
    }

    return (
        <div className='mt-4'>
            <form onSubmit={handleSearchSubmit}>
                <div className='sm:flex'>
                    <input
                        type='text'
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className='p-2 text-lg border border-gray-300 rounded-sm w-full'
                        placeholder='Search by Tags'
                    />
                    <button
                        type='submit'
                        className='py-2 px-4 bg-blue-500 hover:bg-blue-600 transition text-white rounded-sm text-lg mt-2 sm:mt-0 ml-0 sm:ml-2 w-full sm:w-auto'
                    >Search</button>
                </div>
            </form>
        </div>
    )
}

export default SearchBar