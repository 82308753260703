import { useState } from 'react';
import { useAuthContext } from '../useAuthContext';

export const useCreateTrip = () => {
    const { user } = useAuthContext();
    
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const createTrip = async (tripName, tripIdentifier, tripStart) => {
        setIsLoading(true);

        const response = await fetch('/api/trip/create', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            body: JSON.stringify({tripName, tripIdentifier, tripStart})
        });

        const json = await response.json();

        if (!response.ok) {
            setIsLoading(false);
            setError(json.error);
        }

        if (response.ok) {
            setIsLoading(false);
            setError(null);
        }
    }

    return { createTrip, isLoading, error }
}