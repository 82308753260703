import { useState } from 'react';

import { useAuthContext } from '../useAuthContext';

export const useAddFeedbackVote = () => {
    const { user } = useAuthContext();

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const addFeedbackVote = async (feedbackId) => {
        setIsLoading(true);

        const response = await fetch('/api/feedback/vote', {
            method: 'POST',
            body: JSON.stringify({ feedbackId }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        });

        const json = await response.json();

        if (!response.ok) {
            setIsLoading(false);
            setError(json.error);

            return false;
        }

        if (response.ok) {
            setIsLoading(false);
            setError(null);

            return json;
        }
    }

    return { addFeedbackVote, isLoading, error }
}