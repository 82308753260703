import { useState } from 'react';

import { useCreateActivity } from '../../hooks/trip/useCreateActivity';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import LinearProgress from '@mui/material/LinearProgress';

const TripActivities = ({ tripId, media, activities, selectedActivityId, setSelectedActivityId, tripDataIsLoading }) => {
    const [newActivity, setNewActivity] = useState('');
    const [showCreateActivity, setShowCreateActivity] = useState(false);
    const [activityCreated, setActivityCreated] = useState(false);

    const { createActivity, isLoading, error } = useCreateActivity();

    const handleSubmitActivity = async (event) => {
        event.preventDefault();

        setActivityCreated(false);

        const newActivityJson = await createActivity(tripId, newActivity);
        
        if (newActivityJson) {
            setActivityCreated(true);
            console.log(newActivityJson);
        }
    }

    const selectActivity = (activityId) => {
        if (selectedActivityId === activityId) {
            setSelectedActivityId('');
        } else {
            setSelectedActivityId(activityId);
        }
    }

    return (
        <div className='mt-4'>
            <h3 className='text-4xl'>Activities</h3>
            {tripDataIsLoading ? (
                <LinearProgress variant='indeterminate' />
            ) : (
                <>
                    {activities.length > 0 && (
                        <div className='mt-2'>
                            <div className='grid gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4'>
                                {activities.map((a) => (
                                    <Card 
                                        key={a._id} 
                                        onClick={() => selectActivity(a._id)}
                                        className={a._id === selectedActivityId ? 'border-2 border-blue-500' : 'border-2 border-transparent'}
                                    >
                                        <CardContent className='text-center'>
                                            <h4 className='text-xl'>{a.name}</h4>
                                            <p className='mt-2'>Media: {media.filter((m) => m.activityId === a._id).length}</p>
                                        </CardContent>
                                    </Card>
                                ))}
                            </div>
                        </div>
                    )}
                </>
            )}
            <button 
                onClick={() => setShowCreateActivity(!showCreateActivity)}
                className='underline mt-2'
            >Add New Activity</button>
            {showCreateActivity && (
                <>
                    {error && <p className='text-red-400'>{error}</p>}
                    <form onSubmit={handleSubmitActivity} className='mt-2'>
                        <p>New Activity:</p>
                        <input 
                            type='text' 
                            value={newActivity}
                            onChange={(e) => setNewActivity(e.target.value)} 
                            placeholder='Hiking to the Castle'
                            className='p-2 border border-gray-300 rounded-sm'
                        />
                        <button type='submit' disabled={isLoading} className='py-2 px-4 bg-blue-500 hover:bg-blue-600 transition text-white'>Add</button>
                    </form>
                    {isLoading && <p>Creating activity...</p>}
                    {activityCreated && <p>Success!</p>}
                </>
            )}
        </div>
    )
}

export default TripActivities