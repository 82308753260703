// React Utilities
import { useState } from 'react';

// Hooks
import { useUpdateStartDate } from '../../hooks/trip/useUpdateStartDate';

// DayJS
import dayjs from 'dayjs';

// MUI Components
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const EditTrip = ({tripStartDate, setTripStartDate, tripId, setShowEdit}) => {
    const [newDate, setNewDate] = useState(tripStartDate);
    
    const { updateStartDate, isLoading, error } = useUpdateStartDate();

    const handleDateSubmit = async () => {
        const updatedTrip = await updateStartDate(newDate, tripId);

        if (updatedTrip) {
            setTripStartDate(updatedTrip.startDate);
            setShowEdit(false);
        }
    }

    return (
        <div>
            {error && <p className='text-red-400'>{error}</p>}
            <DatePicker value={dayjs(newDate)} onChange={(nd) => setNewDate(nd)} />
            <button 
                type='button' 
                disabled={isLoading} 
                onClick={handleDateSubmit}
                className='py-4 px-4 bg-blue-500 hover:bg-blue-600 transition text-white rounded-sm ml-2'
            >Set Date</button>
        </div>
    )
}

export default EditTrip