import { createContext, useReducer, useEffect } from 'react';

export const AuthContext = createContext();

export const authReducer = (state, action) => {
    switch(action.type) {
        case 'LOGIN':
            return {
                user: action.payload
            }
        case 'LOGOUT':
            return {
                user: null
            }
        default:
            return state
    }
}

export const AuthContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, {
        user: null
    });

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('tsb_user'));

        if (user) {
            if (new Date().getTime() > user.expiry) {
                localStorage.removeItem('tsb_user');
                localStorage.setItem('tsb_expired', JSON.stringify({ expired: true }));
            } else {
                dispatch({type: 'LOGIN', payload: user});
            }
        }
    }, []);

    console.log('AuthContext State:', state);

    return (
        <AuthContext.Provider value={{...state, dispatch}}>
            { children }
        </AuthContext.Provider>
    )
}