import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const PricingTable = ({ accountType, setAccountType, setAccountTypeSelected }) => {
    
    const handleSelectAccountType = (type) => {
        setAccountType(type);
        setAccountTypeSelected(true);
    }

    return (
        <div>
            <div className='mt-8 grid gap-4 grid-cols-1 md:grid-cols-3'>
                <Card className={(accountType === 'free') ? 'border-2 border-blue-500' : 'border-2 border-gray-200'}>
                    <CardActionArea onClick={() => handleSelectAccountType('free')} className='flex flex-col justify-start h-full'>
                        <div className='text-center p-4'>
                            <div>
                                <h3 className='text-2xl font-bold'>Free Plan</h3>
                                <p className='mt-4 text-sm'>PRICE</p>
                                <h3 className='mt-4 text-6xl font-bold'>$0</h3>
                                <p className='mt-4 text-sm'>Per Month</p>
                            </div>
                            <div className='mt-8'>
                                <h4 className='text-xl'>Includes</h4>
                                <List>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckCircleOutlineIcon color='primary' />
                                        </ListItemIcon>
                                        <ListItemText>
                                            {'Unlimited Trips'}
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckCircleOutlineIcon color='primary' />
                                        </ListItemIcon>
                                        <ListItemText>
                                            {'Upload Images & Videos'}
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckCircleOutlineIcon color='primary' />
                                        </ListItemIcon>
                                        <ListItemText>
                                            {'500MB Storage'}
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckCircleOutlineIcon color='primary' />
                                        </ListItemIcon>
                                        <ListItemText>
                                            {'Access to Local Recommendations'}
                                        </ListItemText>
                                    </ListItem>
                                </List>
                            </div>
                        </div>
                    </CardActionArea>
                </Card>
                <Card className={(accountType === 'travel') ? 'border-2 border-blue-500' : 'border-2 border-gray-200'}>
                    <CardActionArea onClick={() => handleSelectAccountType('travel')}>
                        <div className='text-center p-4'>
                            <div>
                                <h3 className='text-2xl font-bold'>Travel Plan</h3>
                                <p className='mt-4 text-sm'>PRICE</p>
                                <h3 className='mt-4 text-6xl font-bold'>$0.99</h3>
                                <p className='mt-4 text-sm'>Per Month</p>
                            </div>
                            <div className='mt-8'>
                                <h4 className='text-xl'>Includes</h4>
                                <List>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckCircleOutlineIcon color='primary' />
                                        </ListItemIcon>
                                        <ListItemText>
                                            {'Unlimited Trips'}
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckCircleOutlineIcon color='primary' />
                                        </ListItemIcon>
                                        <ListItemText>
                                            {'Upload Images & Videos'}
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckCircleOutlineIcon color='primary' />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <span className='font-bold'>{'5GB Storage'}</span>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckCircleOutlineIcon color='primary' />
                                        </ListItemIcon>
                                        <ListItemText>
                                            {'Access to Local Recommendations'}
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckCircleOutlineIcon color='primary' />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <span className='font-bold'>{'Bookmark Recommendations'}</span>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckCircleOutlineIcon color='primary' />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <span className='font-bold'>{'Add Bookmarks to Your Trips'}</span>
                                        </ListItemText>
                                    </ListItem>
                                </List>
                            </div>
                        </div>
                    </CardActionArea>
                </Card>
                <Card className={(accountType === 'adventure') ? 'border-2 border-blue-500' : 'border-2 border-gray-200'}>
                    <CardActionArea onClick={() => handleSelectAccountType('adventure')}>
                        <div className='text-center p-4'>
                            <div>
                                <h3 className='text-2xl font-bold'>Adventure Plan</h3>
                                <p className='mt-4 text-sm'>PRICE</p>
                                <h3 className='mt-4 text-6xl font-bold'>$4.99</h3>
                                <p className='mt-4 text-sm'>Per Month</p>
                            </div>
                            <div className='mt-8'>
                                <h4 className='text-xl'>Includes</h4>
                                <List>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckCircleOutlineIcon color='primary' />
                                        </ListItemIcon>
                                        <ListItemText>
                                            {'Unlimited Trips'}
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckCircleOutlineIcon color='primary' />
                                        </ListItemIcon>
                                        <ListItemText>
                                            {'Upload Images & Videos'}
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckCircleOutlineIcon color='primary' />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <span className='font-bold'>{'50GB Storage'}</span>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckCircleOutlineIcon color='primary' />
                                        </ListItemIcon>
                                        <ListItemText>
                                            {'Access to Local Recommendations'}
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckCircleOutlineIcon color='primary' />
                                        </ListItemIcon>
                                        <ListItemText>
                                            {'Bookmark Recommendations'}
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckCircleOutlineIcon color='primary' />
                                        </ListItemIcon>
                                        <ListItemText>
                                            {'Add Bookmarks to Your Trips'}
                                        </ListItemText>
                                    </ListItem>
                                </List>
                            </div>
                        </div>
                    </CardActionArea>
                </Card>
            </div>
        </div>
    )
}

export default PricingTable