import { useEffect, useState } from 'react';
import { useAuthContext } from '../../hooks/useAuthContext';

import { useDeleteMedia } from '../../hooks/media/useDeleteMedia';
import { useDeleteMultipleMedia } from '../../hooks/media/useDeleteMultipleMedia';
import { useSetMediaActivity } from '../../hooks/media/useSetMediaActivity';
import { useDownloadSelected } from '../../hooks/media/useDownloadSelected';
import { useSetMediaTag } from '../../hooks/media/useSetMediaTag';
import { useGetMediaTags } from '../../hooks/media/useGetMediaTags';

// MUI Components
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// MUI Icons
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';

const TripMedia = ({ tripId, media, setMedia, activities, selectedActivityId }) => {
    // User Context
    const { user } = useAuthContext();

    // Fetch Media Loading States
    const [mediaIsLoading, setMediaIsLoading] = useState(false);

    // Fetch Media Error State
    const [error, setError] = useState(null);
    
    // Delete Media Hook Data
    const { deleteMedia, error:deleteMediaError } = useDeleteMedia();
    const { deleteMultipleMedia, isLoading:deleteMultipleIsLoading, error:deleteMultipleError } = useDeleteMultipleMedia();

    // Download Selected Hook Data
    const { downloadSelected, isLoading: downloadIsLoading, error: downloadError } = useDownloadSelected();
    
    // Set Tag Hooks
    const { getMediaTags, isLoading: getMediaTagsIsLoading, error: getMediaTagsError } = useGetMediaTags();
    const { setMediaTag, isLoading: setMediaTagIsLoading, error: setMediaTagError, setError: setErrorSetMediaTag } = useSetMediaTag();
    
    // Trip Media Arrays
    const [mediaImages, setMediaImages] = useState([]);
    const [mediaPageImages, setMediaPageImages] = useState([]);
    const [mediaVideos, setMediaVideos] = useState([]);

    // Selected Media Array
    const [selectedMediaData, setSelectedMediaData] = useState([]);

    // Menu States & Functions
    const [menuMedia, setMenuMedia] = useState(null);
    const [menuMediaTags, setMenuMediaTags] = useState([]);
    const [menuAnchor, setMenuAnchor] = useState(null);
    const menuOpen = Boolean(menuAnchor);
    const handleMenuClick = (event, media) => {
        setMenuMedia(media);
        setMenuMediaTags([]);
        setMenuAnchor(event.currentTarget);
    }
    const handleMenuClose = () => { 
        setMenuAnchor(null); 
    }

    // Pagination States & Functions
    const [page, setPage] = useState(1);
    const nextPage = () => { 
        const newPage = (page * 8 > media.length) ? page : page + 1
        setPage(newPage);
        setMediaPageImages(mediaImages.slice(((newPage * 8) - 8), (newPage * 8)));
    }
    const prevPage = () => { 
        const newPage = (page === 1) ? 1 : page - 1 
        setPage(newPage);
        setMediaPageImages(mediaImages.slice(((newPage * 8) - 8), (newPage * 8)));
    }

    // Delete Media States & Functions
    const [mediaToDelete, setMediaToDelete] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);
    const handleDialogOpen = () => {
        setMediaToDelete(menuMedia._id); 
        setDialogOpen(true) 
    }
    const handleDialogClose = () => { 
        setMediaToDelete('');
        setDialogOpen(false) 
    }
    const handleDeleteMedia = async (mediaId) => {
        const deleted = await deleteMedia(mediaId);

        if (deleted) {
            setMedia(media.filter((m) => m._id !== mediaId));
            setSelectedMediaData(selectedMediaData.filter((sm) => sm._id !== mediaId));
            handleDialogClose();
            handleMenuClose();
        }
    }

    // Delete Multiple States & Functions
    const [dialogDeleteMultipleOpen, setDialogDeleteMultipleOpen] = useState(false);
    const handleDialogDeleteMultipleOpen = () => { setDialogDeleteMultipleOpen(true) }
    const handleDialogDeleteMultipleClose = () => { setDialogDeleteMultipleOpen(false) }
    const handleDeleteMultipleMedia = async () => {
        const selectedMediaIds = selectedMediaData.filter((sm) => sm.checked).map((smi) => smi._id);
        const deleted = await deleteMultipleMedia(selectedMediaIds);

        if (deleted) {
            setMedia(media.filter((m) => !selectedMediaIds.includes(m._id)));
            setSelectedMediaData(selectedMediaData.filter((sm) => !selectedMediaIds.includes(sm._id)));
            setDialogDeleteMultipleOpen(false);
        }
    }

    // Set Activity States & Functions
    const { setMediaActivity } = useSetMediaActivity();
    const [chosenActivity, setChosenActivity] = useState('');
    const [chosenActivityMultiple, setChosenActivityMultiple] = useState('');
    const [dialogActivityOpen, setDialogActivityOpen] = useState(false);
    const [dialogActivityMultipleOpen, setDialogActivityMultipleOpen] = useState(false);
    const handleDialogActivityOpen = () => { setDialogActivityOpen(true) }
    const handleDialogActivityClose = () => { setDialogActivityOpen(false) }
    const handleDialogActivityMultipleOpen = () => { setDialogActivityMultipleOpen(true) }
    const handleDialogActivityMultipleClose = () => { setDialogActivityMultipleOpen(false) }
    const handleSetActivity = async () => {
        if (tripId && menuMedia && chosenActivity) {
            const setMediaActivityResponse = await setMediaActivity(tripId, [menuMedia._id], chosenActivity);

            if (setMediaActivityResponse) {
                handleDialogActivityClose();
            }
        }
    }
    const handleSetActivityMultiple = async () => {
        if (tripId && chosenActivityMultiple && selectedMediaData.length > 0) {
            const selectedMediaIds = selectedMediaData.filter((sm) => sm.checked).map((smi) => smi._id);
            const setMediaActivityResponse = await setMediaActivity(tripId, selectedMediaIds, chosenActivityMultiple);

            if (setMediaActivityResponse) {
                if (setMediaActivityResponse.modifiedAll) {
                    const newMediaUpdated = media.map((m) => {
                        if (selectedMediaIds.includes(m._id)) {
                            let updatedMedia = m;
                            updatedMedia.activityId = chosenActivityMultiple;
                            console.log(`Updated Media ${updatedMedia._id} to Activity ID ${updatedMedia.activityId}`);
                            return updatedMedia;
                        } else {
                            return m;
                        }
                    });
                    setMedia(newMediaUpdated);
                }
                handleDialogActivityMultipleClose();
            }
        }
    }

    // Tags States & Functions
    const [newTag, setNewTag] = useState('');
    const [newTagMultiple, setNewTagMultiple] = useState('');
    const [newTagMultipleSuccess, setNewTagMultipleSuccess] = useState(false);
    const [dialogTagsOpen, setDialogTagsOpen] = useState(false);
    const [dialogTagsMultipleOpen, setDialogTagsMultipleOpen] = useState(false);
    const handleDialogTagsMultipleOpen = () => { setDialogTagsMultipleOpen(true) }
    const handleDialogTagsMultipleClose = () => { 
        setNewTagMultipleSuccess(false);
        setDialogTagsMultipleOpen(false); 
    }
    const handleDialogTagsOpen = async () => { 
        setDialogTagsOpen(true);

        if (menuMedia) {
            const curMediaTags = await getMediaTags(menuMedia._id);
            if (curMediaTags) {
                setMenuMediaTags(curMediaTags);
            }
        }
    }
    const handleDialogTagsClose = () => { 
        setErrorSetMediaTag(null);
        setDialogTagsOpen(false);
        handleMenuClose();
    }
    const handleTagSubmit = async (event) => {
        event.preventDefault();

        if (menuMedia) {
            const tagMediaIds = [menuMedia._id];
            const tagSet = await setMediaTag(newTag, tagMediaIds);

            if (tagSet) {
                setNewTag('');
                setMenuMediaTags(tagSet.tags);
            }
        }
    }
    const handleTagMultipleSubmit = async (event) => {
        event.preventDefault();
        setNewTagMultipleSuccess(false);

        const selectedMediaIds = selectedMediaData.filter((sm) => sm.checked).map((smi) => smi._id);

        const tagSet = await setMediaTag(newTagMultiple, selectedMediaIds);

        if (tagSet) {
            setNewTagMultiple('');
            setNewTagMultipleSuccess(true);
        }
    }

    // Download Media Function
    const handleDownloadMedia = (url) => {
        window.open(url, '_blank');
    }

    const handleDownloadSelected = async () => {
        const selectedMediaIds = selectedMediaData.filter((sm) => sm.checked).map((smi) => smi._id);
        const location = await downloadSelected(selectedMediaIds);
        
        if (location) {
            const link = document.createElement('a');
            link.href = location.location;
            link.target = '_blank';
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        }
    }

    // Select Media Functions
    const handleSelectMedia = (e, m) => {
        const newSelectedMediaData = selectedMediaData.map((sm) => sm._id === m._id ? (
            {
                _id: m._id,
                checked: e.target.checked
            }
        ) : sm);

        setSelectedMediaData(newSelectedMediaData);
    }
    const handleDeselectAll = () => { 
        const newSelectedMediaData = selectedMediaData.map((sm) => (
            {
                _id: sm._id,
                checked: false
            }
        ));
        setSelectedMediaData(newSelectedMediaData); 
    }

    // Full Size Dialog States & Functions
    const [dialogImageMedia, setDialogImageMedia] = useState(null);
    const [dialogImageOpen, setDialogImageOpen] = useState(false);
    const handleDialogImageOpen = (media) => {
        setDialogImageMedia(media);
        setDialogImageOpen(true);
    }
    const handleDialogImageClose = () => {
        setDialogImageMedia(null);
        setDialogImageOpen(false);
    }

    // Cover Photo Functionality
    const handleSetCoverPhoto = async () => {
        if (menuMedia) {
            const coverUrl = (menuMedia.hasThumbnail) ? menuMedia.thumbnailUrl : menuMedia.url;
            const response = await fetch(`/api/media/cover/${tripId}`, {
                method: 'PATCH',
                body: JSON.stringify({coverUrl}),
                headers: {
                    'Authorization': `Bearer ${user.token}`,
                    'Content-Type': 'application/json'
                }
            });
            const json = await response.json();

            if (!response.ok) {
                console.log(json);
                handleMenuClose();
            }

            if (response.ok) {
                console.log(json);
                handleMenuClose();
            }
        }
    }

    useEffect(() => {
        const fetchMedia = async () => {      
            setMediaIsLoading(true);

            const response = await fetch(`/api/media/trip/${tripId}`, {
                headers: { 'Authorization': `Bearer ${user.token}` }
            });
            const json = await response.json();
            
            if (!response.ok) {
                setMediaIsLoading(false);
                setError(json.error);
            }

            if (response.ok) {
                setMediaIsLoading(false);

                // This needs to be adjusted for MP4s
                const mediaImages = json.filter((m) => m.fileType !== 'video/quicktime');
                const mediaVideos = json.filter((m) => m.fileType === 'video/quicktime');
                setMedia(json);
                setMediaImages(mediaImages);
                setMediaPageImages(mediaImages.slice(0, 8));

                const newSelectedMediaData = mediaImages.map((mi) => (
                    {
                        _id: mi._id,
                        checked: false
                    }
                ));

                setSelectedMediaData(newSelectedMediaData);
                //setMediaVideos(mediaVideos);
            }
        }

        if (user) {
            fetchMedia();
        }
    }, [user, tripId, setMedia]);

    useEffect(() => {
        const newPage = 1;
        setPage(newPage);
        const newMediaImages = media.filter((m) => m.fileType !== 'video/quicktime').filter((m) => (selectedActivityId) ? m.activityId === selectedActivityId : true);
        setMediaImages(newMediaImages);
        setMediaPageImages(newMediaImages.slice(((newPage * 8) - 8), (newPage * 8)));
    }, [media, selectedActivityId]);

    useEffect(() => {
        if (activities.length > 0) {
            setChosenActivity(activities[0]._id);
            setChosenActivityMultiple(activities[0]._id);
        }
    }, [activities]);

    return (
        <div className='mt-10'>
            {error && <p className='text-red-400 my-2'>Error Fetching Media: {error}</p>}
            <div className='flex justify-between items-center'>
                <h3 className='text-4xl'>Photos</h3>
                <div>
                    <button type='button' onClick={prevPage} className='py-2 px-4 bg-blue-500 hover:bg-blue-600 transition text-white rounded-sm mr-2'>Prev</button>
                    <button type='button' onClick={nextPage} className='py-2 px-4 bg-blue-500 hover:bg-blue-600 transition text-white rounded-sm'>Next</button>
                </div>
            </div>
            {selectedMediaData.filter((sm) => sm.checked === true).length > 0 && (
                <div className='sm:flex sm:justify-end sm:mt-2'>
                    <div className='mt-2 sm:mt-0 sm:flex sm:justify-end sm:mr-2 sm:items-center'>
                        {downloadError && <p className='text-red-400 sm:mr-2'>There was a problem with your download.</p>}
                        <button 
                            type='button'
                            disabled={downloadIsLoading}
                            onClick={handleDownloadSelected}
                            className='py-2 px-4 w-full sm:w-auto bg-green-600 hover:bg-green-700 transition text-white rounded-sm'
                        >
                            {downloadIsLoading ? (
                                <div className='flex items-center'>
                                    <span className='mr-2'>Download Loading...</span>
                                    <CircularProgress variant='indeterminate' size='1rem' sx={{ color: 'white' }} />
                                </div>
                            ) : (
                                <>Download ({selectedMediaData.filter((sm) => sm.checked === true).length})</>
                            )}
                        </button>
                    </div>
                    <div className='mt-2 sm:mt-0 sm:flex sm:justify-end sm:mr-2'>
                        <button
                            type='button'
                            onClick={handleDialogActivityMultipleOpen}
                            className='py-2 px-4 w-full sm:w-auto bg-purple-500 hover:bg-purple-600 transition text-white rounded-sm'
                        >Update Activities</button>
                    </div>
                    <div className='mt-2 sm:mt-0 sm:flex sm:justify-end sm:mr-2'>
                        <button
                            type='button'
                            onClick={handleDialogTagsMultipleOpen}
                            className='py-2 px-4 w-full sm:w-auto bg-orange-500 hover:bg-orange-600 transition text-white rounded-sm'
                        >Add Tags</button>
                    </div>
                    <div className='mt-2 sm:mt-0 sm:flex sm:justify-end sm:mr-2'>
                        <button
                            type='button'
                            onClick={handleDialogDeleteMultipleOpen}
                            className='py-2 px-4 w-full sm:w-auto bg-red-500 hover:bg-red-600 transition text-white rounded-sm'
                        ><DeleteIcon /></button>
                    </div>
                    <div className='mt-2 sm:mt-0 sm:flex sm:justify-end'>
                        <button
                            type='button'
                            onClick={handleDeselectAll}
                            className='py-2 px-4 w-full sm:w-auto bg-gray-500 hover:bg-gray-600 transition text-white rounded-sm'
                        >Deselect All</button>
                    </div>
                </div>
            )}
            {mediaIsLoading ? (
                <div className='mt-4'>
                    <LinearProgress variant='indeterminate' />
                </div>
            ) : (
                <div className='mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 items-center gap-4'>
                    {mediaPageImages.length > 0 && (
                        mediaPageImages.filter((m) => selectedActivityId ? m.activityId === selectedActivityId : true).map((m) => (
                            <Card key={m._id} className=''>
                                <CardHeader 
                                    subheader={`Uploaded by ${m.user[0].name}`} 
                                    action={
                                        <IconButton aria-label='options' onClick={(e) => handleMenuClick(e, m)} >
                                            <MoreVertIcon />
                                        </IconButton>
                                    }
                                />
                                <CardContent sx={{ padding: 0, height: '300px' }} onClick={() => handleDialogImageOpen(m)}>
                                    <img src={m.hasThumbnail ? m.thumbnailUrl : m.url} alt='' className='h-full w-full object-cover trip-media-image' />
                                </CardContent>
                                <CardActions className='flex justify-between'>
                                    <Checkbox
                                        checked={selectedMediaData.filter((sm) => (sm._id === m._id && sm.checked === true)).length === 1}
                                        onChange={(event) => handleSelectMedia(event, m)}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                    <Button size='small' color='success' onClick={() => handleDownloadMedia(m.url)}>Download Original</Button>
                                </CardActions>
                            </Card>
                        ))
                    )}
                </div>
            )}
            <div className='mt-4 flex justify-end'>
                <button type='button' onClick={prevPage} className='py-2 px-4 bg-blue-500 hover:bg-blue-600 transition text-white rounded-sm mr-2'>Prev</button>
                <button type='button' onClick={nextPage} className='py-2 px-4 bg-blue-500 hover:bg-blue-600 transition text-white rounded-sm'>Next</button>
            </div>
            <h3 className='text-4xl mt-10'>Videos</h3>
            <div className='mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 items-center gap-4'>
                {mediaVideos.length > 0 && (
                    mediaVideos.map((m) => (
                        <Card key={m._id} className=''>
                            <CardContent sx={{ padding: 0 }}>
                                <video controls='controls' width='800' height='600'>
                                    <source src={m.url} />
                                </video>
                            </CardContent>
                            <CardActions>
                                <Button size='small' color='primary'>View Original</Button>
                                <Button size='small' color='success' onClick={() => handleDownloadMedia(m.url)}>Download</Button>
                                <Button size='small' color='error' onClick={() => handleDialogOpen(m._id)}>Delete</Button>
                            </CardActions>
                        </Card>
                    ))
                )}
            </div>
            <Menu
                id='media-menu'
                anchorEl={menuAnchor}
                open={menuOpen}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={handleDialogTagsOpen}>Add Tags</MenuItem>
                <MenuItem onClick={handleDialogActivityOpen}>Set Activity</MenuItem>
                <MenuItem onClick={handleSetCoverPhoto}>Make Cover Photo</MenuItem>
                <MenuItem onClick={handleDialogOpen} sx={{ color: 'red' }}>Delete</MenuItem>
            </Menu>
            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>
                    {"Delete Image?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        Are you sure that you would like to delete this image?
                    </DialogContentText>
                    {deleteMediaError && (
                        <div className='mt-4'>
                            <DialogContentText color='error'>{deleteMediaError}</DialogContentText>
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDeleteMedia(mediaToDelete)} color='error'>Delete</Button>
                    <Button onClick={handleDialogClose} color='primary'>Cancel</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={dialogDeleteMultipleOpen}
                onClose={handleDialogDeleteMultipleClose}
                aria-labelledby='alert-dialog-delete-multiple-title'
                aria-describedby='alert-dialog-delete-multiple-description'
            >
                <DialogTitle id='alert-dialog-delete-multiple-title'>
                    {"Delete Multiple Images?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-delete-multiple-description'>
                        Are you sure that you would like to delete {selectedMediaData.filter((sm) => sm.checked).length} image(s)?
                    </DialogContentText>
                    {deleteMultipleError && (
                        <div className='mt-4'>
                            <DialogContentText color='error'>
                                {deleteMultipleError}
                            </DialogContentText>
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button 
                        color='error' 
                        onClick={handleDeleteMultipleMedia} 
                        disabled={deleteMultipleIsLoading}
                    >
                        {deleteMultipleIsLoading ? (
                            <>{'Deleting...'}</>
                        ) : (
                            <>{'Delete'}</>
                        )}
                    </Button>
                    <Button 
                        color='primary'
                        onClick={handleDialogDeleteMultipleClose} 
                    >Cancel</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={dialogActivityOpen}
                onClose={handleDialogActivityClose}
                aria-labelledby='alert-dialog-activity-title'
                aria-describedby='alert-dialog-activity-description'
            >
                <DialogTitle id='alert-dialog-activity-title'>
                    {'Set Activity'}
                </DialogTitle>
                <DialogContent>
                    {activities.length > 0 ? (
                        <>
                            <DialogContentText id='alert-dialog-activity-description'>
                                Choose the Activity this media corresponds to from the dropdown below.
                            </DialogContentText>
                            <select 
                                value={chosenActivity} 
                                onChange={(e) => setChosenActivity(e.target.value)} 
                                className='mt-4 w-full border border-gray-300 rounded-sm p-2'
                            >
                                {activities.map((a) => (
                                    <option key={a._id} value={a._id}>{a.name}</option>
                                ))}
                            </select>
                        </>
                    ) : (
                        <DialogContentText>There are no activities in this trip. Try creating one first!</DialogContentText>
                    )}
                </DialogContent>
                <DialogActions>
                    {activities.length > 0 ? (
                        <>
                            <Button onClick={() => handleDialogActivityClose()} color='error'>Cancel</Button>
                            <Button onClick={() => handleSetActivity()} color='primary'>Set Activity</Button>
                        </>
                    ) : (
                        <Button onClick={handleDialogActivityClose} color='primary'>Okay</Button>
                    )}
                </DialogActions>
            </Dialog>
            <Dialog
                open={dialogActivityMultipleOpen}
                onClose={handleDialogActivityMultipleClose}
                aria-labelledby='alert-dialog-activity-multiple-title'
                aria-describedby='alert-dialog-activity-multiple-description'
            >
                <DialogTitle id='alert-dialog-activity-multiple-title'>
                    {'Set Activity'}
                </DialogTitle>
                <DialogContent>
                    {activities.length > 0 ? (
                        <>
                            <DialogContentText id='alert-dialog-activity-multiple-description'>
                                Choose the Activity for the selected ({selectedMediaData.filter((sm) => sm.checked).length}) items from the dropdown below.
                            </DialogContentText>
                            <select 
                                value={chosenActivityMultiple} 
                                onChange={(e) => setChosenActivityMultiple(e.target.value)} 
                                className='mt-4 w-full border border-gray-300 rounded-sm p-2'
                            >
                                {activities.map((a) => (
                                    <option key={a._id} value={a._id}>{a.name}</option>
                                ))}
                            </select>
                        </>
                    ) : (
                        <DialogContentText>There are no activities in this trip. Try creating one first!</DialogContentText>
                    )}
                </DialogContent>
                <DialogActions>
                    {activities.length > 0 ? (
                        <>
                            <Button onClick={() => handleDialogActivityMultipleClose()} color='error'>Cancel</Button>
                            <Button onClick={() => handleSetActivityMultiple()} color='primary'>Set Activity</Button>
                        </>
                    ) : (
                        <Button onClick={handleDialogActivityMultipleClose} color='primary'>Okay</Button>
                    )}
                </DialogActions>
            </Dialog>
            <Dialog
                open={dialogTagsOpen}
                onClose={handleDialogTagsClose}
                aria-labelledby='alert-dialog-tags-title'
                aria-describedby='alert-dialog-tags-description'
            >
                <DialogTitle id='alert-dialog-tags-title'>
                    {'Add Tags to Media'}
                </DialogTitle>
                <DialogContent>
                    {menuMedia && (
                        <div>
                            <img src={menuMedia.thumbnailUrl ? menuMedia.thumbnailUrl : menuMedia.url } alt='' />
                            <form onSubmit={handleTagSubmit} className='mt-2'>
                                <input 
                                    type='text'
                                    value={newTag}
                                    onChange={(e) => setNewTag(e.target.value)}
                                    placeholder='mountains, food, etc'
                                    className='p-2 border border-gray-300 rounded-sm text-lg w-full sm:w-auto'
                                />
                                <button
                                    type='submit'
                                    className='py-2 px-4 bg-blue-500 hover:bg-blue-600 transition text-white text-lg rounded-sm w-full sm:w-auto ml-0 sm:ml-2 mt-2 sm:mt-0'
                                >Add Tag</button>
                            </form>
                            {setMediaTagError && <p className='text-red-400'>{setMediaTagError}</p>}
                            {setMediaTagIsLoading && <div className='mt-2'><LinearProgress variant='indeterminate' /></div>}
                            {getMediaTagsIsLoading ? (
                                <LinearProgress variant='indeterminate' />
                            ) : (
                                <div className='mt-2'>
                                    {menuMediaTags.length > 0 && (
                                        <Stack direction='row' spacing={1}>
                                            {menuMediaTags.map((mmt) => (
                                                <Chip key={mmt} label={mmt} />
                                            ))}
                                        </Stack>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogTagsClose} color='primary'>Done</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={dialogTagsMultipleOpen}
                onClose={handleDialogTagsMultipleClose}
                aria-labelledby='alert-dialog-tags-multiple-title'
                aria-describedby='alert-dialog-tags-multiple-description'
            >
                <DialogTitle id='alert-dialog-tags-multiple-title'>
                    {`Add Tags to (${selectedMediaData.filter((sm) => sm.checked).length}) Media`}
                </DialogTitle>
                <DialogContent>
                    <form onSubmit={handleTagMultipleSubmit}>
                        <input
                            type='text'
                            value={newTagMultiple}
                            onChange={(e) => setNewTagMultiple(e.target.value)}
                            placeholder='mountains, food, etc'
                            className='p-2 border border-gray-300 rounded-sm text-lg w-full sm:w-auto'
                        />
                        <button
                            type='submit'
                            className='py-2 px-4 bg-blue-500 hover:bg-blue-600 transition text-white text-lg rounded-sm w-full sm:w-auto ml-0 sm:ml-2 mt-2 sm:mt-0'
                        >Add Tag</button>
                    </form>
                    {setMediaTagIsLoading && (
                        <div className='mt-2'>
                            <LinearProgress variant='indeterminate' />
                        </div>
                    )}
                    {(!setMediaTagIsLoading && newTagMultipleSuccess) && <p className='text-green-400 text-lg mt-2'>Tags Added Successfully!</p>}
                </DialogContent>
            </Dialog>
            <Dialog
                open={dialogImageOpen}
                onClose={handleDialogImageClose}
                fullWidth={true}
                maxWidth={'lg'}
            >
                    {dialogImageMedia && <img src={dialogImageMedia.url} alt='' />}
            </Dialog>
        </div>
    )
}

export default TripMedia