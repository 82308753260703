import { useState } from 'react';
import { useAuthContext } from '../useAuthContext';

export const useSendInvitation = () => {
    const { user } = useAuthContext();

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const sendInvitation = async (tripId, toEmail) => {
        const response = await fetch('/api/invitation/create', {
            method: 'POST',
            body: JSON.stringify({tripId, toEmail}),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        });

        const json = await response.json();

        if (!response.ok) {
            setIsLoading(false);
            setError(json.error);
            
            return false;
        }

        if (response.ok) {
            setIsLoading(false);
            setError(null);

            return json;
        }
    }

    return { sendInvitation, isLoading, error }
}