import { useEffect, useState } from 'react';

// Hooks
import { useAuthContext } from '../hooks/useAuthContext';
import { useSubmitFeedback } from '../hooks/feedback/useSubmitFeedback';
import { useAddFeedbackVote } from '../hooks/feedback/useAddFeedbackVote';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActionArea from '@mui/material/CardActionArea';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';

// MUI Icons
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

const sortFeedbackByVotesCount = (x, y) => {
    if (x.votes.length > y.votes.length) {
        return -1;
    } else if (x.votes.length < y.votes.length) {
        return 1;
    } else {
        return 0;
    }
}

const Feedback = () => {
    const { user } = useAuthContext();
    const { submitFeedback, isLoading: submitFeedbackIsLoading, error: submitFeedbackError } = useSubmitFeedback();
    const { addFeedbackVote, isLoading: feedbackVoteIsLoading, error: feedbackVoteError } = useAddFeedbackVote();
    const [selectedFeedbackType, setSelectedFeedbackType] = useState('feature');

    const [allFeedback, setAllFeedback] = useState([]);
    const [myVotes, setMyVotes] = useState([]);

    const [voteFeedbackId, setVoteFeedbackId] = useState('');
    const [isLoadingFeedback, setIsLoadingFeedback] = useState(false);
    const [fetchFeedbackError, setFetchFeedbackError] = useState(null);

    const [showFeedbackForm, setShowFeedbackForm] = useState(false);
    const [newFeedbackType, setNewFeedbackType] = useState('');
    const [newFeedbackTitle, setNewFeedbackTitle] = useState('');
    const [newFeedbackDescription, setNewFeedbackDescription] = useState('');

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const handleSnackbarClose = () => { setSnackbarOpen(false) }

    const handleSubmitFeedbackForm = async (event) => {
        event.preventDefault();

        const newFeedback = await submitFeedback(newFeedbackType, newFeedbackTitle, newFeedbackDescription);

        if (newFeedback) {
            // Handle new feedback
            setNewFeedbackType('');
            setNewFeedbackTitle('');
            setNewFeedbackDescription('');
            setShowFeedbackForm(false);
            setSnackbarOpen(true);
            setAllFeedback([...allFeedback, newFeedback]);
        }
    }

    const handleVote = async (feedbackId) => {
        setVoteFeedbackId(feedbackId);
        const newVoteData = await addFeedbackVote(feedbackId);

        if (newVoteData) {
            if (newVoteData.addOrRemove === 'add' || newVoteData.addOrRemove === 'remove') {
                const feedbackToUpdate = allFeedback.find((fb) => fb._id === newVoteData.feedbackVote.feedbackId);
                
                let replacementVotes;

                if (newVoteData.addOrRemove === 'add') {
                    setMyVotes([...myVotes, newVoteData.feedbackVote]);
                    replacementVotes = [...feedbackToUpdate.votes, newVoteData.feedbackVote];
                } else if (newVoteData.addOrRemove === 'remove') {
                    setMyVotes(myVotes.filter((vote) => vote._id !== newVoteData.feedbackVote._id));
                    replacementVotes = feedbackToUpdate.votes.filter((v) => v._id !== newVoteData.feedbackVote._id);
                }

                const replacementFeedback = {
                    ...feedbackToUpdate,
                    votes: replacementVotes
                };

                const newAllFeedback = allFeedback.map((fb) => fb._id === replacementFeedback._id ? replacementFeedback : fb).sort(sortFeedbackByVotesCount);
                
                setAllFeedback(newAllFeedback);
            }
        }
    }

    useEffect(() => {
        const fetchFeedback = async () => {
            setIsLoadingFeedback(true);

            const response = await fetch('/api/feedback/all', {
                headers: { 'Authorization': `Bearer ${user.token}` }
            });

            const json = await response.json();

            if (!response.ok) {
                setIsLoadingFeedback(false);
                setFetchFeedbackError(json.error);
            }

            if (response.ok) {
                setIsLoadingFeedback(false);
                setFetchFeedbackError(null);

                setAllFeedback(json);
            }
        }

        const fetchMyVotes = async () => {
            const response = await fetch('/api/feedback/user/votes', {
                headers: { 'Authorization': `Bearer ${user.token}` }
            });

            const json = await response.json();

            if (response.ok) {
                setMyVotes(json);
            }
        }

        if (user) {
            fetchFeedback();
            fetchMyVotes();
        }
    }, []);

    return (
        <div>
            <div className='flex justify-between items-center'>
                <h3 className='font-bold text-2xl'>Feedback</h3>
                <button
                    type='button'
                    onClick={() => setShowFeedbackForm(true)}
                    className='py-2 px-4 bg-blue-500 hover:bg-blue-600 transition text-white font-bold rounded-sm'
                >Add New</button>
            </div>
            {showFeedbackForm && (
                <div className='mt-4 bg-white p-4'>
                    <form onSubmit={handleSubmitFeedbackForm}>
                        <div className='flex justify-between items-center'>
                            <h4 className='text-lg font-bold'>Submit Feedback</h4>
                            <button 
                                type='button'
                                onClick={() => setShowFeedbackForm(false)} 
                                className='underline hover:no-underline'
                            >Cancel</button>
                        </div>
                        <div className='mt-4'>
                            <p>Feedback Type:</p>
                            <RadioGroup
                                row
                                name='new-feedback-type'
                                value={newFeedbackType}
                                onChange={(e) => setNewFeedbackType(e.target.value)}
                            >
                                <FormControlLabel value='feature' control={<Radio />} label='Feature' />
                                <FormControlLabel value='bug' control={<Radio />} label='Bug' />
                            </RadioGroup>
                        </div>
                        {(newFeedbackType === 'feature' || newFeedbackType === 'bug') && (
                            <>
                                <div className='mt-4'>
                                    {newFeedbackType === 'feature' && <p>New Feature Title:</p>}
                                    {newFeedbackType === 'bug' && <p>Bug / Error Title:</p>}
                                    <input
                                        type='text'
                                        className='w-full p-2 border border-gray-300 rounded-sm'
                                        value={newFeedbackTitle}
                                        onChange={(e) => setNewFeedbackTitle(e.target.value)}
                                    />
                                </div>
                                <div className='mt-4'>
                                    {newFeedbackType === 'feature' && <p>Describe the New Feature:</p>}
                                    {newFeedbackType === 'bug' && <p>Describe the error / issue:</p>}
                                    <textarea
                                        type='text'
                                        className='w-full p-2 border border-gray-300 rounded-sm'
                                        value={newFeedbackDescription}
                                        onChange={(e) => setNewFeedbackDescription(e.target.value)}
                                    ></textarea>
                                </div>
                                <div className='mt-4'>
                                    <button
                                        type='submit'
                                        className='py-2 px-4 bg-blue-500 hover:bg-blue-600 transition text-white font-bold rounded-sm'
                                    >Submit Feedback</button>
                                </div>
                            </>
                        )}
                    </form>
                </div>
            )}
            <div className='mt-4 grid grid-cols-1 md:grid-cols-2 gap-4'>
                <Card className={selectedFeedbackType === 'feature' ? 'border-2 border-blue-500' : ''}>
                    <CardActionArea onClick={() => setSelectedFeedbackType('feature')}>
                        <CardHeader title={'Feature Requests'} />
                    </CardActionArea>
                </Card>
                <Card className={selectedFeedbackType === 'bug' ? 'border-2 border-blue-500' : ''}>
                    <CardActionArea onClick={() => setSelectedFeedbackType('bug')}>
                        <CardHeader title={'Bugs (Problems)'} />
                    </CardActionArea>
                </Card>
            </div>
            <div className='mt-4'>
                {selectedFeedbackType === 'features' && (
                    <h3 className='font-bold text-lg'>New Feature Requests</h3>
                )}
                {selectedFeedbackType === 'bugs' && (
                    <h3 className='font-bold text-lg'>Bugs (Problems & Errors)</h3>
                )}
                {isLoadingFeedback && (
                    <div className='mt-4'>
                        <LinearProgress variant='indeterminate' />
                    </div>
                )}
                {allFeedback.length > 0 ? (
                    <div className='bg-white p-2'>
                        {allFeedback.filter((fb) => fb.type === selectedFeedbackType).map((fb) => (
                            <div key={fb._id} className='mt-2 mb-2 pb-4 flex justify-start gap-4 items-start border-b border-gray-300'>
                                <div className='w-32 text-center'>
                                    <div>
                                        <p className='text-xl'>{fb.votes.length}</p>
                                        <p className='text-sm'>Votes</p>
                                        <div className='mt-1'>
                                            <button
                                                type='button'
                                                disabled={feedbackVoteIsLoading}
                                                onClick={() => handleVote(fb._id)}
                                            >
                                                {(feedbackVoteIsLoading && fb._id === voteFeedbackId) ? (
                                                    <div className='h-8'>
                                                        <CircularProgress variant='indeterminate' size={'2rem'} />
                                                    </div>
                                                ) : (
                                                    <div className='h-8'>
                                                        <ThumbUpIcon 
                                                            color={
                                                                myVotes.find((vote) => vote.feedbackId === fb._id) ? 'primary' : 'action'
                                                            } 
                                                        />
                                                    </div>
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className='w-full'>
                                    <h4 className='font-bold'>{fb.title}</h4>
                                    <p className='mt-2'>{fb.description}</p>
                                </div>
                            </div>
                        ))}
                        {allFeedback.filter((fb) => fb.type === selectedFeedbackType).length === 0 && (
                            <div className='p-2'>
                                {selectedFeedbackType === 'feature' && (
                                    <>
                                        <p>There are currently no Open Feature Requests.</p>
                                        <button onClick={() => setShowFeedbackForm(true)} className='underline hover:no-underline'>Submit one now!</button>
                                    </>
                                )}
                                {selectedFeedbackType === 'bug' && (
                                    <>
                                        <p className='mb-2'>There are currently no Open Bug Fixes / Problems.</p>
                                        <button onClick={() => setShowFeedbackForm(true)} className='underline hover:no-underline'>Submit one now!</button>
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                ) : (
                    <div className='mt-4'>
                        <h4 className='font-bold'>No Feedback has been Submitted</h4>
                        <p className='mt-2'>
                            Be the first &&nbsp;
                            <button onClick={() => setShowFeedbackForm(true)} className='underline hover:no-underline'>Submit Feedback</button>
                            &nbsp;now!</p>
                    </div>
                )}
            </div>
            <Snackbar open={snackbarOpen} autoHideDuration={5000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={'success'} variant={'filled'} sx={{ width: '100%' }}>
                    Feedback Submitted!
                </Alert>
            </Snackbar>
        </div>
    )
}

export default Feedback