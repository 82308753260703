import { useState } from "react";
import { useAuthContext } from "../useAuthContext";

export const useDeleteMedia = () => {
    const { user } = useAuthContext();

    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const deleteMedia = async (mediaId) => {
        setIsLoading(true);

        const response = await fetch(`/api/media/delete/${mediaId}`, {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${user.token}` }
        });

        const json = await response.json();

        if (!response.ok) {
            setIsLoading(false);
            setError(json.error);
            //console.log(json);
            return false;
        }

        if (response.ok) {
            setIsLoading(false);
            setError(null);

            return true;
        }
    }

    return { deleteMedia, error, isLoading }
}