import { useState } from 'react';

import { useAuthContext } from '../useAuthContext';

export const useUpdateAccountType = () => {
    const { user } = useAuthContext();

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const updateAccountType = async (newAccountType) => {
        setIsLoading(true);

        const response = await fetch('/api/stripe/account/update', {
            method: 'POST',
            body: JSON.stringify({ newAccountType }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        });

        const json = await response.json();

        if (!response.ok) {
            setIsLoading(false);
            setError(json.error);

            return false;
        }

        if (response.ok) {
            setIsLoading(false);
            setError(null);

            return true;
        }
    }

    return { updateAccountType, isLoading, error }
}