import { useInvitationContext } from '../hooks/useInvitationContext';

import { useSendInvitationResponse } from '../hooks/invitation/useSendInvitationResponse';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

const Invitations = () => {
    const { invitations } = useInvitationContext();
    const { sendInvitationResponse } = useSendInvitationResponse();

    const respondToInvitation = async (response, invitationId) => {
        await sendInvitationResponse(response, invitationId);
    }

    return (
        <div>
            <h2 className='text-4xl mb-4'>Invitations</h2>
            {invitations.length > 0 && (
                invitations.filter((i) => i.seen === false).map((i) => (
                    <Card key={i._id}>
                        <CardContent>
                            <h3 className='text-xl mb-4'>New Invitation</h3>
                            <h3 className='text-xl font-bold'>Details:</h3>
                            <p className='text-xl'>{i.trip[0].name}</p>
                            <p className='text-xl'>{i.trip[0].identifier}</p>
                            <div className='mt-4 flex justify-end'>
                                <button
                                    type='button'
                                    onClick={() => respondToInvitation('accept', i._id)}
                                    className='py-2 px-4 bg-blue-500 hover:bg-blue-600 transition text-white rounded-sm'
                                >Accept</button>
                                <button
                                    type='button'
                                    onClick={() => respondToInvitation('decline', i._id)}
                                    className='py-2 px-4 bg-red-500 hover:bg-red-600 transition text-white rounded-sm ml-2'
                                >Decline</button>
                            </div>
                        </CardContent>
                    </Card>
                ))
            )}
        </div>
    )
}

export default Invitations